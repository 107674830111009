/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      client_id
      client_full_name
      jira_project_name
      client_type
      is_active
      is_advantage_member
      is_strategy_partner
      is_mid_level_member
      exclude_from_match_qualifier
      featurize
      people_platform
      action_platform
      transaction_platform
      exhaust_platform
      sms_platform
      transactions_offline_platform
      curate_subscriber
      curate_frequency
      curate_days
      curate_delivery_auto_upload
      suppressed_domains
      actblue_entity_ids
      sfg_surveys_client_ids
      goop_client_ids
      totem_client_ids
      salsa_organization_key
      ngp_everyaction_database_name
      ngp_everyaction_server_name
      salesforce_location
      pardot_location
      hub_source_path
      facebook_account_ids
      facebook_locations
      google_account_ids
      bing_account_ids
      twitter_account_ids
      outbrain_account_ids
      code_dictionary_url
      annelewis_slack_channel
      timezone
      mailchimp_directory
      classy_org_id
      ngp_actblue_manual_codes
      share_cap_max_shares
      share_cap_months
      hubspot_stitch_integration_name
      tatango_client_id
      inbox_monster_rule
      campaign_monitor_location
      pause_data
      donor_threshold
      donor_category
      donor_prop
      email_validation_provider
      multi_member_clients
      multi_member_sms_clients
      multi_member_mid_level_clients
      stw_subaccount_ids
      notes
      is_low_inventory
      limited_model_universe
      fiscal_year_start
      multi_member_required
      multi_member_mid_level_required
      delivery_count
      createdAt
      updatedAt
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      client_id
      client_full_name
      jira_project_name
      client_type
      is_active
      is_advantage_member
      is_strategy_partner
      is_mid_level_member
      exclude_from_match_qualifier
      featurize
      people_platform
      action_platform
      transaction_platform
      exhaust_platform
      sms_platform
      transactions_offline_platform
      curate_subscriber
      curate_frequency
      curate_days
      curate_delivery_auto_upload
      suppressed_domains
      actblue_entity_ids
      sfg_surveys_client_ids
      goop_client_ids
      totem_client_ids
      salsa_organization_key
      ngp_everyaction_database_name
      ngp_everyaction_server_name
      salesforce_location
      pardot_location
      hub_source_path
      facebook_account_ids
      facebook_locations
      google_account_ids
      bing_account_ids
      twitter_account_ids
      outbrain_account_ids
      code_dictionary_url
      annelewis_slack_channel
      timezone
      mailchimp_directory
      classy_org_id
      ngp_actblue_manual_codes
      share_cap_max_shares
      share_cap_months
      hubspot_stitch_integration_name
      tatango_client_id
      inbox_monster_rule
      campaign_monitor_location
      pause_data
      donor_threshold
      donor_category
      donor_prop
      email_validation_provider
      multi_member_clients
      multi_member_sms_clients
      multi_member_mid_level_clients
      stw_subaccount_ids
      notes
      is_low_inventory
      limited_model_universe
      fiscal_year_start
      multi_member_required
      multi_member_mid_level_required
      delivery_count
      createdAt
      updatedAt
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      client_id
      client_full_name
      jira_project_name
      client_type
      is_active
      is_advantage_member
      is_strategy_partner
      is_mid_level_member
      exclude_from_match_qualifier
      featurize
      people_platform
      action_platform
      transaction_platform
      exhaust_platform
      sms_platform
      transactions_offline_platform
      curate_subscriber
      curate_frequency
      curate_days
      curate_delivery_auto_upload
      suppressed_domains
      actblue_entity_ids
      sfg_surveys_client_ids
      goop_client_ids
      totem_client_ids
      salsa_organization_key
      ngp_everyaction_database_name
      ngp_everyaction_server_name
      salesforce_location
      pardot_location
      hub_source_path
      facebook_account_ids
      facebook_locations
      google_account_ids
      bing_account_ids
      twitter_account_ids
      outbrain_account_ids
      code_dictionary_url
      annelewis_slack_channel
      timezone
      mailchimp_directory
      classy_org_id
      ngp_actblue_manual_codes
      share_cap_max_shares
      share_cap_months
      hubspot_stitch_integration_name
      tatango_client_id
      inbox_monster_rule
      campaign_monitor_location
      pause_data
      donor_threshold
      donor_category
      donor_prop
      email_validation_provider
      multi_member_clients
      multi_member_sms_clients
      multi_member_mid_level_clients
      stw_subaccount_ids
      notes
      is_low_inventory
      limited_model_universe
      fiscal_year_start
      multi_member_required
      multi_member_mid_level_required
      delivery_count
      createdAt
      updatedAt
    }
  }
`;
export const createTimeStamps = /* GraphQL */ `
  mutation CreateTimeStamps(
    $input: CreateTimeStampsInput!
    $condition: ModelTimeStampsConditionInput
  ) {
    createTimeStamps(input: $input, condition: $condition) {
      client_id
      column_name
      changed_to
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateTimeStamps = /* GraphQL */ `
  mutation UpdateTimeStamps(
    $input: UpdateTimeStampsInput!
    $condition: ModelTimeStampsConditionInput
  ) {
    updateTimeStamps(input: $input, condition: $condition) {
      client_id
      column_name
      changed_to
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteTimeStamps = /* GraphQL */ `
  mutation DeleteTimeStamps(
    $input: DeleteTimeStampsInput!
    $condition: ModelTimeStampsConditionInput
  ) {
    deleteTimeStamps(input: $input, condition: $condition) {
      client_id
      column_name
      changed_to
      id
      createdAt
      updatedAt
    }
  }
`;
export const createRolePermissions = /* GraphQL */ `
  mutation CreateRolePermissions(
    $input: CreateRolePermissionsInput!
    $condition: ModelRolePermissionsConditionInput
  ) {
    createRolePermissions(input: $input, condition: $condition) {
      id
      role_type
      description
      can_manage_permissions
      can_manage_users
      client_table_column_permissions
      createdAt
      updatedAt
    }
  }
`;
export const updateRolePermissions = /* GraphQL */ `
  mutation UpdateRolePermissions(
    $input: UpdateRolePermissionsInput!
    $condition: ModelRolePermissionsConditionInput
  ) {
    updateRolePermissions(input: $input, condition: $condition) {
      id
      role_type
      description
      can_manage_permissions
      can_manage_users
      client_table_column_permissions
      createdAt
      updatedAt
    }
  }
`;
export const deleteRolePermissions = /* GraphQL */ `
  mutation DeleteRolePermissions(
    $input: DeleteRolePermissionsInput!
    $condition: ModelRolePermissionsConditionInput
  ) {
    deleteRolePermissions(input: $input, condition: $condition) {
      id
      role_type
      description
      can_manage_permissions
      can_manage_users
      client_table_column_permissions
      createdAt
      updatedAt
    }
  }
`;
export const createGroupPermissions = /* GraphQL */ `
  mutation CreateGroupPermissions(
    $input: CreateGroupPermissionsInput!
    $condition: ModelGroupPermissionsConditionInput
  ) {
    createGroupPermissions(input: $input, condition: $condition) {
      group_id
      client_id
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateGroupPermissions = /* GraphQL */ `
  mutation UpdateGroupPermissions(
    $input: UpdateGroupPermissionsInput!
    $condition: ModelGroupPermissionsConditionInput
  ) {
    updateGroupPermissions(input: $input, condition: $condition) {
      group_id
      client_id
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroupPermissions = /* GraphQL */ `
  mutation DeleteGroupPermissions(
    $input: DeleteGroupPermissionsInput!
    $condition: ModelGroupPermissionsConditionInput
  ) {
    deleteGroupPermissions(input: $input, condition: $condition) {
      group_id
      client_id
      id
      createdAt
      updatedAt
    }
  }
`;
export const createClientAttributePermissions = /* GraphQL */ `
  mutation CreateClientAttributePermissions(
    $input: CreateClientAttributePermissionsInput!
    $condition: ModelClientAttributePermissionsConditionInput
  ) {
    createClientAttributePermissions(input: $input, condition: $condition) {
      client_permission_id
      client_full_name
      jira_project_name
      client_type
      is_active
      is_advantage_member
      is_strategy_partner
      is_mid_level_member
      exclude_from_match_qualifier
      featurize
      people_platform
      action_platform
      transaction_platform
      exhaust_platform
      sms_platform
      transactions_offline_platform
      curate_subscriber
      curate_frequency
      curate_days
      curate_delivery_auto_upload
      suppressed_domains
      actblue_entity_ids
      sfg_surveys_client_id
      goop_client_id
      salsa_organization_key
      ngp_everyaction_database_name
      ngp_everyaction_server_name
      salesforce_location
      pardot_location
      hub_source_path
      facebook_account_ids
      facebook_locations
      google_account_ids
      bing_account_ids
      twitter_account_ids
      outbrain_account_ids
      code_dictionary_url
      annelewis_slack_channel
      timezone
      mailchimp_directory
      classy_org_id
      ngp_actblue_manual_codes
      share_cap_max_shares
      share_cap_months
      hubspot_stitch_integration_name
      tatango_client_id
      inbox_monster_rule
      campaign_monitor_location
      pause_data
      donor_threshold
      donor_category
      donor_prop
      email_validation_provider
      multi_member_clients
      multi_member_sms_clients
      multi_member_mid_level_clients
      stw_subaccount_ids
      notes
      is_low_inventory
      limited_model_universe
      fiscal_year_start
      multi_member_required
      multi_member_mid_level_required
      delivery_count
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateClientAttributePermissions = /* GraphQL */ `
  mutation UpdateClientAttributePermissions(
    $input: UpdateClientAttributePermissionsInput!
    $condition: ModelClientAttributePermissionsConditionInput
  ) {
    updateClientAttributePermissions(input: $input, condition: $condition) {
      client_permission_id
      client_full_name
      jira_project_name
      client_type
      is_active
      is_advantage_member
      is_strategy_partner
      is_mid_level_member
      exclude_from_match_qualifier
      featurize
      people_platform
      action_platform
      transaction_platform
      exhaust_platform
      sms_platform
      transactions_offline_platform
      curate_subscriber
      curate_frequency
      curate_days
      curate_delivery_auto_upload
      suppressed_domains
      actblue_entity_ids
      sfg_surveys_client_id
      goop_client_id
      salsa_organization_key
      ngp_everyaction_database_name
      ngp_everyaction_server_name
      salesforce_location
      pardot_location
      hub_source_path
      facebook_account_ids
      facebook_locations
      google_account_ids
      bing_account_ids
      twitter_account_ids
      outbrain_account_ids
      code_dictionary_url
      annelewis_slack_channel
      timezone
      mailchimp_directory
      classy_org_id
      ngp_actblue_manual_codes
      share_cap_max_shares
      share_cap_months
      hubspot_stitch_integration_name
      tatango_client_id
      inbox_monster_rule
      campaign_monitor_location
      pause_data
      donor_threshold
      donor_category
      donor_prop
      email_validation_provider
      multi_member_clients
      multi_member_sms_clients
      multi_member_mid_level_clients
      stw_subaccount_ids
      notes
      is_low_inventory
      limited_model_universe
      fiscal_year_start
      multi_member_required
      multi_member_mid_level_required
      delivery_count
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteClientAttributePermissions = /* GraphQL */ `
  mutation DeleteClientAttributePermissions(
    $input: DeleteClientAttributePermissionsInput!
    $condition: ModelClientAttributePermissionsConditionInput
  ) {
    deleteClientAttributePermissions(input: $input, condition: $condition) {
      client_permission_id
      client_full_name
      jira_project_name
      client_type
      is_active
      is_advantage_member
      is_strategy_partner
      is_mid_level_member
      exclude_from_match_qualifier
      featurize
      people_platform
      action_platform
      transaction_platform
      exhaust_platform
      sms_platform
      transactions_offline_platform
      curate_subscriber
      curate_frequency
      curate_days
      curate_delivery_auto_upload
      suppressed_domains
      actblue_entity_ids
      sfg_surveys_client_id
      goop_client_id
      salsa_organization_key
      ngp_everyaction_database_name
      ngp_everyaction_server_name
      salesforce_location
      pardot_location
      hub_source_path
      facebook_account_ids
      facebook_locations
      google_account_ids
      bing_account_ids
      twitter_account_ids
      outbrain_account_ids
      code_dictionary_url
      annelewis_slack_channel
      timezone
      mailchimp_directory
      classy_org_id
      ngp_actblue_manual_codes
      share_cap_max_shares
      share_cap_months
      hubspot_stitch_integration_name
      tatango_client_id
      inbox_monster_rule
      campaign_monitor_location
      pause_data
      donor_threshold
      donor_category
      donor_prop
      email_validation_provider
      multi_member_clients
      multi_member_sms_clients
      multi_member_mid_level_clients
      stw_subaccount_ids
      notes
      is_low_inventory
      limited_model_universe
      fiscal_year_start
      multi_member_required
      multi_member_mid_level_required
      delivery_count
      id
      createdAt
      updatedAt
    }
  }
`;
export const createUsers = /* GraphQL */ `
  mutation CreateUsers(
    $input: CreateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    createUsers(input: $input, condition: $condition) {
      id
      firstname
      lastname
      email
      role_id
      allowed_client
      blocked_clients
      createdAt
      updatedAt
    }
  }
`;
export const updateUsers = /* GraphQL */ `
  mutation UpdateUsers(
    $input: UpdateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    updateUsers(input: $input, condition: $condition) {
      id
      firstname
      lastname
      email
      role_id
      allowed_client
      blocked_clients
      createdAt
      updatedAt
    }
  }
`;
export const deleteUsers = /* GraphQL */ `
  mutation DeleteUsers(
    $input: DeleteUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    deleteUsers(input: $input, condition: $condition) {
      id
      firstname
      lastname
      email
      role_id
      allowed_client
      blocked_clients
      createdAt
      updatedAt
    }
  }
`;
export const createSources = /* GraphQL */ `
  mutation CreateSources(
    $input: CreateSourcesInput!
    $condition: ModelSourcesConditionInput
  ) {
    createSources(input: $input, condition: $condition) {
      id
      createdAt
      client_id
      channel_category
      channel
      source
      from_goop
      send_date
      send_cost
      send_size
      codes {
        items {
          id
          createdAt
          client_id
          code
          source
          fixed_cost_media_spend
          fixed_cost_cpa_override
          revenue_category
          audience
          audience_category
          type
          platforms_with_spend
          total_spend
          most_recent_spend
          most_recent_spend_date
          most_recent_transaction_date
          revenue
          transactions
          acquisitions
          most_recent_acquisition_date
          from_goop
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const updateSources = /* GraphQL */ `
  mutation UpdateSources(
    $input: UpdateSourcesInput!
    $condition: ModelSourcesConditionInput
  ) {
    updateSources(input: $input, condition: $condition) {
      id
      createdAt
      client_id
      channel_category
      channel
      source
      from_goop
      send_date
      send_cost
      send_size
      codes {
        items {
          id
          createdAt
          client_id
          code
          source
          fixed_cost_media_spend
          fixed_cost_cpa_override
          revenue_category
          audience
          audience_category
          type
          platforms_with_spend
          total_spend
          most_recent_spend
          most_recent_spend_date
          most_recent_transaction_date
          revenue
          transactions
          acquisitions
          most_recent_acquisition_date
          from_goop
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const deleteSources = /* GraphQL */ `
  mutation DeleteSources(
    $input: DeleteSourcesInput!
    $condition: ModelSourcesConditionInput
  ) {
    deleteSources(input: $input, condition: $condition) {
      id
      createdAt
      client_id
      channel_category
      channel
      source
      from_goop
      send_date
      send_cost
      send_size
      codes {
        items {
          id
          createdAt
          client_id
          code
          source
          fixed_cost_media_spend
          fixed_cost_cpa_override
          revenue_category
          audience
          audience_category
          type
          platforms_with_spend
          total_spend
          most_recent_spend
          most_recent_spend_date
          most_recent_transaction_date
          revenue
          transactions
          acquisitions
          most_recent_acquisition_date
          from_goop
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const createCodes = /* GraphQL */ `
  mutation CreateCodes(
    $input: CreateCodesInput!
    $condition: ModelCodesConditionInput
  ) {
    createCodes(input: $input, condition: $condition) {
      id
      createdAt
      client_id
      code
      source
      fixed_cost_media_spend
      fixed_cost_cpa_override
      revenue_category
      audience
      audience_category
      type
      platforms_with_spend
      total_spend
      most_recent_spend
      most_recent_spend_date
      most_recent_transaction_date
      revenue
      transactions
      acquisitions
      most_recent_acquisition_date
      from_goop
      updatedAt
    }
  }
`;
export const updateCodes = /* GraphQL */ `
  mutation UpdateCodes(
    $input: UpdateCodesInput!
    $condition: ModelCodesConditionInput
  ) {
    updateCodes(input: $input, condition: $condition) {
      id
      createdAt
      client_id
      code
      source
      fixed_cost_media_spend
      fixed_cost_cpa_override
      revenue_category
      audience
      audience_category
      type
      platforms_with_spend
      total_spend
      most_recent_spend
      most_recent_spend_date
      most_recent_transaction_date
      revenue
      transactions
      acquisitions
      most_recent_acquisition_date
      from_goop
      updatedAt
    }
  }
`;
export const deleteCodes = /* GraphQL */ `
  mutation DeleteCodes(
    $input: DeleteCodesInput!
    $condition: ModelCodesConditionInput
  ) {
    deleteCodes(input: $input, condition: $condition) {
      id
      createdAt
      client_id
      code
      source
      fixed_cost_media_spend
      fixed_cost_cpa_override
      revenue_category
      audience
      audience_category
      type
      platforms_with_spend
      total_spend
      most_recent_spend
      most_recent_spend_date
      most_recent_transaction_date
      revenue
      transactions
      acquisitions
      most_recent_acquisition_date
      from_goop
      updatedAt
    }
  }
`;
export const createTransactions = /* GraphQL */ `
  mutation CreateTransactions(
    $input: CreateTransactionsInput!
    $condition: ModelTransactionsConditionInput
  ) {
    createTransactions(input: $input, condition: $condition) {
      id
      client_id
      email
      code
      amount
      transaction_dt
      recurring_period
      recurrence
      recurring_type
      refund_flag
      exclude_flag
      exclusion_updated_dt
      foreign_platform
      foreign_client_key
      foreign_transaction_key
      mobile_flag
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const updateTransactions = /* GraphQL */ `
  mutation UpdateTransactions(
    $input: UpdateTransactionsInput!
    $condition: ModelTransactionsConditionInput
  ) {
    updateTransactions(input: $input, condition: $condition) {
      id
      client_id
      email
      code
      amount
      transaction_dt
      recurring_period
      recurrence
      recurring_type
      refund_flag
      exclude_flag
      exclusion_updated_dt
      foreign_platform
      foreign_client_key
      foreign_transaction_key
      mobile_flag
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const deleteTransactions = /* GraphQL */ `
  mutation DeleteTransactions(
    $input: DeleteTransactionsInput!
    $condition: ModelTransactionsConditionInput
  ) {
    deleteTransactions(input: $input, condition: $condition) {
      id
      client_id
      email
      code
      amount
      transaction_dt
      recurring_period
      recurrence
      recurring_type
      refund_flag
      exclude_flag
      exclusion_updated_dt
      foreign_platform
      foreign_client_key
      foreign_transaction_key
      mobile_flag
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const createInsightsAcqDeliveries = /* GraphQL */ `
  mutation CreateInsightsAcqDeliveries(
    $input: CreateInsightsAcqDeliveriesInput!
    $condition: ModelInsightsAcqDeliveriesConditionInput
  ) {
    createInsightsAcqDeliveries(input: $input, condition: $condition) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const updateInsightsAcqDeliveries = /* GraphQL */ `
  mutation UpdateInsightsAcqDeliveries(
    $input: UpdateInsightsAcqDeliveriesInput!
    $condition: ModelInsightsAcqDeliveriesConditionInput
  ) {
    updateInsightsAcqDeliveries(input: $input, condition: $condition) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const deleteInsightsAcqDeliveries = /* GraphQL */ `
  mutation DeleteInsightsAcqDeliveries(
    $input: DeleteInsightsAcqDeliveriesInput!
    $condition: ModelInsightsAcqDeliveriesConditionInput
  ) {
    deleteInsightsAcqDeliveries(input: $input, condition: $condition) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const createInsightsAcqDeliveriesCohort = /* GraphQL */ `
  mutation CreateInsightsAcqDeliveriesCohort(
    $input: CreateInsightsAcqDeliveriesCohortInput!
    $condition: ModelInsightsAcqDeliveriesCohortConditionInput
  ) {
    createInsightsAcqDeliveriesCohort(input: $input, condition: $condition) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      cohort
      cohort_name
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const updateInsightsAcqDeliveriesCohort = /* GraphQL */ `
  mutation UpdateInsightsAcqDeliveriesCohort(
    $input: UpdateInsightsAcqDeliveriesCohortInput!
    $condition: ModelInsightsAcqDeliveriesCohortConditionInput
  ) {
    updateInsightsAcqDeliveriesCohort(input: $input, condition: $condition) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      cohort
      cohort_name
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const deleteInsightsAcqDeliveriesCohort = /* GraphQL */ `
  mutation DeleteInsightsAcqDeliveriesCohort(
    $input: DeleteInsightsAcqDeliveriesCohortInput!
    $condition: ModelInsightsAcqDeliveriesCohortConditionInput
  ) {
    deleteInsightsAcqDeliveriesCohort(input: $input, condition: $condition) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      cohort
      cohort_name
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const createInsightsAdvDeliveries = /* GraphQL */ `
  mutation CreateInsightsAdvDeliveries(
    $input: CreateInsightsAdvDeliveriesInput!
    $condition: ModelInsightsAdvDeliveriesConditionInput
  ) {
    createInsightsAdvDeliveries(input: $input, condition: $condition) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const updateInsightsAdvDeliveries = /* GraphQL */ `
  mutation UpdateInsightsAdvDeliveries(
    $input: UpdateInsightsAdvDeliveriesInput!
    $condition: ModelInsightsAdvDeliveriesConditionInput
  ) {
    updateInsightsAdvDeliveries(input: $input, condition: $condition) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const deleteInsightsAdvDeliveries = /* GraphQL */ `
  mutation DeleteInsightsAdvDeliveries(
    $input: DeleteInsightsAdvDeliveriesInput!
    $condition: ModelInsightsAdvDeliveriesConditionInput
  ) {
    deleteInsightsAdvDeliveries(input: $input, condition: $condition) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const createInsightsAdvDeliveriesCohort = /* GraphQL */ `
  mutation CreateInsightsAdvDeliveriesCohort(
    $input: CreateInsightsAdvDeliveriesCohortInput!
    $condition: ModelInsightsAdvDeliveriesCohortConditionInput
  ) {
    createInsightsAdvDeliveriesCohort(input: $input, condition: $condition) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      cohort
      cohort_name
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const updateInsightsAdvDeliveriesCohort = /* GraphQL */ `
  mutation UpdateInsightsAdvDeliveriesCohort(
    $input: UpdateInsightsAdvDeliveriesCohortInput!
    $condition: ModelInsightsAdvDeliveriesCohortConditionInput
  ) {
    updateInsightsAdvDeliveriesCohort(input: $input, condition: $condition) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      cohort
      cohort_name
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const deleteInsightsAdvDeliveriesCohort = /* GraphQL */ `
  mutation DeleteInsightsAdvDeliveriesCohort(
    $input: DeleteInsightsAdvDeliveriesCohortInput!
    $condition: ModelInsightsAdvDeliveriesCohortConditionInput
  ) {
    deleteInsightsAdvDeliveriesCohort(input: $input, condition: $condition) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      cohort
      cohort_name
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const createInsightsReacDeliveries = /* GraphQL */ `
  mutation CreateInsightsReacDeliveries(
    $input: CreateInsightsReacDeliveriesInput!
    $condition: ModelInsightsReacDeliveriesConditionInput
  ) {
    createInsightsReacDeliveries(input: $input, condition: $condition) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const updateInsightsReacDeliveries = /* GraphQL */ `
  mutation UpdateInsightsReacDeliveries(
    $input: UpdateInsightsReacDeliveriesInput!
    $condition: ModelInsightsReacDeliveriesConditionInput
  ) {
    updateInsightsReacDeliveries(input: $input, condition: $condition) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const deleteInsightsReacDeliveries = /* GraphQL */ `
  mutation DeleteInsightsReacDeliveries(
    $input: DeleteInsightsReacDeliveriesInput!
    $condition: ModelInsightsReacDeliveriesConditionInput
  ) {
    deleteInsightsReacDeliveries(input: $input, condition: $condition) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const createInsightsReacDeliveriesCohort = /* GraphQL */ `
  mutation CreateInsightsReacDeliveriesCohort(
    $input: CreateInsightsReacDeliveriesCohortInput!
    $condition: ModelInsightsReacDeliveriesCohortConditionInput
  ) {
    createInsightsReacDeliveriesCohort(input: $input, condition: $condition) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      cohort
      cohort_name
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      recurring_flag
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const updateInsightsReacDeliveriesCohort = /* GraphQL */ `
  mutation UpdateInsightsReacDeliveriesCohort(
    $input: UpdateInsightsReacDeliveriesCohortInput!
    $condition: ModelInsightsReacDeliveriesCohortConditionInput
  ) {
    updateInsightsReacDeliveriesCohort(input: $input, condition: $condition) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      cohort
      cohort_name
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      recurring_flag
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const deleteInsightsReacDeliveriesCohort = /* GraphQL */ `
  mutation DeleteInsightsReacDeliveriesCohort(
    $input: DeleteInsightsReacDeliveriesCohortInput!
    $condition: ModelInsightsReacDeliveriesCohortConditionInput
  ) {
    deleteInsightsReacDeliveriesCohort(input: $input, condition: $condition) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      cohort
      cohort_name
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      recurring_flag
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const createDeliveries = /* GraphQL */ `
  mutation CreateDeliveries(
    $input: CreateDeliveriesInput!
    $condition: ModelDeliveriesConditionInput
  ) {
    createDeliveries(input: $input, condition: $condition) {
      id
      client_id
      cost_per_name
      cost_per_order
      number_of_free_names
      reason
      approved_by
      invoice_number
      payment_received
      databricks_job_id
      delivery_config
      delivery_date
      delivery_id
      delivery_type
      file_uploads
      pm_comments
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateDeliveries = /* GraphQL */ `
  mutation UpdateDeliveries(
    $input: UpdateDeliveriesInput!
    $condition: ModelDeliveriesConditionInput
  ) {
    updateDeliveries(input: $input, condition: $condition) {
      id
      client_id
      cost_per_name
      cost_per_order
      number_of_free_names
      reason
      approved_by
      invoice_number
      payment_received
      databricks_job_id
      delivery_config
      delivery_date
      delivery_id
      delivery_type
      file_uploads
      pm_comments
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteDeliveries = /* GraphQL */ `
  mutation DeleteDeliveries(
    $input: DeleteDeliveriesInput!
    $condition: ModelDeliveriesConditionInput
  ) {
    deleteDeliveries(input: $input, condition: $condition) {
      id
      client_id
      cost_per_name
      cost_per_order
      number_of_free_names
      reason
      approved_by
      invoice_number
      payment_received
      databricks_job_id
      delivery_config
      delivery_date
      delivery_id
      delivery_type
      file_uploads
      pm_comments
      status
      createdAt
      updatedAt
    }
  }
`;
