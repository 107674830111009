import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Card, Box, Button, Accordion, AccordionSummary, AccordionDetails, Tooltip, IconButton, InputLabel,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import DeliveriesDropDown from '../deliveriesFields/DeliveriesDropdown';
import DeliveriesTextField from '../deliveriesFields/DeliveriesTextField';
import { logReactErrBoundaryError } from '../../utils';
import Alert from '../../utils/Alert';
import FallbackOnError from '../FallbackOnError';
import logger from '../../utils/logger';

function AcquisitionGeneralFields({
  formData,
  setFormData,
  clientNamesList,
  clientsList,
  handleChange,
  confirmedClient,
  setConfirmedClient,
  setAlertError,
  alertError,
  hasTDCAdminPermission,
}) {
  const getClientType = (clientName) => {
    /**
     * Will search for client inside clientsList and set form data values for:
     * client type (nonprofit or political), client_id, and set default PM fields
     * @param {string} clientName - the client's name
     */
    const clientValue = clientsList.find((cl) => clientName === cl.client.toLowerCase());
    setFormData({
      ...formData,
      clientType: clientValue.client_type,
      client_id: clientValue.client_id,
      current_delivery_count: (clientValue.delivery_count + 1),
      // set default PM values
      days_ago_active: clientValue.client_type === 'political' ? 30 : 365,
      donor_floor_type: clientValue.client_type === 'nonprofit' ? 'Nonprofit' : 'All',
      desired_donor_floor: clientValue.client_type === 'nonprofit' ? 0.8 : 0.3,
    });
  };

  const confirmSelection = () => {
    /**
     * Handle setting confirmed client when user clicks on the button
     * This will show on the front end when a user is not scoped to a client
     * Will throw error if user clicks on button without choosing a client from the dropdown
     */
    if (!formData.client_id) {
      setAlertError({
        severity: 'error',
        message: 'Please select a client from the dropdown first',
      });
    } else {
      setConfirmedClient(true);
      getClientType(formData.client_id);
      setAlertError({});
    }
  };

  const handleDateRangeChange = (newValue) => {
    /**
     * This function handles picking a date using MUI's date picker.
     * If the date is null, an alert will de displayed to the user.
     * If a date is passed in, then we will format the date as YYYY-MM-DD and
     * save to the formData object.
     * @param {object} newValue - date object from MUI date picker
     */
    if (newValue == null) {
      setAlertError({
        severity: 'error',
        message: 'Delivery date cannot be empty',
      });
    } else {
      setAlertError({});
      const date = new Date(newValue.$d);
      const formattedDate = dayjs(date).format('YYYY-MM-DD');
      setFormData({ ...formData, delivery_date: formattedDate });
    }
  };

  const isWeekend = (date) => {
    const day = date.day();
    return day === 0 || day === 6;
  };

  const minDate = () => {
    const now = dayjs();
    const noon = now.hour(12).minute(0).second(0);
    if (now.isBefore(noon)) {
      return now.add(2, 'day').hour(12).minute(0).second(0);
    }
    return now.add(3, 'day').hour(12).minute(0).second(0);
  };

  useEffect(() => {
    // calculate and set cost_per_order value based on changes to cost_per_name and number_of_names
    setFormData({
      ...formData,
      cost_per_order: Math.floor(formData.cost_per_name * formData.number_of_names),
    });
  }, [formData.cost_per_name, formData.number_of_names]);

  return (
    <ErrorBoundary
      FallbackComponent={FallbackOnError}
      onError={logReactErrBoundaryError}
      onReset={(details) => {
        logger.info('Error boundary resetting: ', details);
        // Todo: Reset the state of your app so the error doesn't happen again
      }}
    >
      <Card className="acquisition-form-card">
        {formData && (
          <Box sx={{ padding: 2 }}>
            <div id="alert-message">
              {Object.keys(alertError).length > 0
                && (
                  <Alert
                    alert={alertError}
                  />
                )}
            </div>
            {formData.client_id && confirmedClient ? (
              <Box id="center-deliveries-box">
                <DeliveriesTextField
                  isDisabled
                  handleChange={handleChange}
                  value={formData.client_id}
                  name="client_id"
                  label="Client"
                  type="text"
                />
              </Box>
            ) : (
              <Box id="center-deliveries-box" sx={{ display: 'flex', flexDirection: 'column' }}>
                <DeliveriesDropDown
                  name="client_id"
                  value={formData.client_id}
                  label="Client*"
                  textLabel="Select a Client"
                  handleChange={handleChange}
                  menuList={clientNamesList}
                />
                <Box sx={{ marginTop: '15px' }}>
                  <Button variant="contained" onClick={confirmSelection}>
                    <CheckIcon />
                    {' '}
                    Confirm Client Selection
                  </Button>
                </Box>
              </Box>
            )}
            <Box id="center-deliveries-box">
              <InputLabel htmlFor="delivery_date">
                Delivery Date *
                <Tooltip title="Deliveries have a two business day delivery window. If you require a delivery to be fulfilled sooner please submit with the soonest available date and flag the delivery and desired timeline for @deliveries_pms in Slack." placement="right-start">
                  <IconButton size="small">
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{
                    width: '500px',
                  }}
                  disablePast
                  value={dayjs(formData.delivery_date)}
                  shouldDisableDate={!hasTDCAdminPermission ? isWeekend : undefined}
                  minDate={!hasTDCAdminPermission ? minDate() : undefined}
                  onChange={(newValue) => handleDateRangeChange(newValue)}
                  format="YYYY-MM-DD"
                  name="delivery_date"
                  slotProps={{
                    textField: {
                      inputProps: {
                        'data-testid': 'date-picker-test',
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            </Box>
            <Box id="center-deliveries-box">
              <DeliveriesTextField
                handleChange={handleChange}
                value={formData.number_of_names}
                name="number_of_names"
                label="Number of Names*"
                type="integer"
              />
            </Box>
            <Box
              id="center-deliveries-box"
              sx={{
                py: 2,
              }}
            >
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography component="span">Free Names</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <DeliveriesTextField
                      handleChange={handleChange}
                      value={formData.number_of_free_names}
                      name="number_of_free_names"
                      label="Number of Free Names"
                      tooltip="Enter in number of free names to be added to delivery. Do not include the free names in the Number of Names field above."
                      type="integer"
                      customWidth="470px"
                    />

                    <DeliveriesTextField
                      handleChange={handleChange}
                      value={formData.reason}
                      name="reason"
                      label="Reason"
                      tooltip="Please provide a short explanation for including free names in the delivery."
                      type="text"
                      customWidth="470px"
                    />
                    <DeliveriesTextField
                      handleChange={handleChange}
                      value={formData.approved_by}
                      name="approved_by"
                      label="Approved By"
                      tooltip="Please confirm before submitting that free names are approved for this delivery"
                      type="text"
                      customWidth="470px"
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box id="center-deliveries-box">
              <DeliveriesTextField
                handleChange={handleChange}
                value={formData.cost_per_name}
                name="cost_per_name"
                label="Cost Per Name*"
                type="integer"
                tooltip="Refer to your client’s Co-Op agreement"
              />
              <DeliveriesTextField
                isDisabled
                handleChange={handleChange}
                value={formData.cost_per_order}
                name="cost_per_order"
                label="Cost Per Order"
                type="integer"
                tooltip="Minimum $7.5k, unless you have explicit approval."
                customHelperText="This field is automatically calculated based on cost per name and number of names"
              />
              <DeliveriesTextField
                handleChange={handleChange}
                value={formData.invoice_number}
                name="invoice_number"
                label="Invoice Number"
                type="text"
              />
              <DeliveriesDropDown
                value={formData.payment_received || ''}
                handleChange={handleChange}
                menuList={['Y', 'N']}
                placeholder="Payment Received (Y/N)"
                name="payment_received"
                label="Payment Received"
              />
            </Box>
          </Box>
        )}
      </Card>
    </ErrorBoundary>
  );
}

export default AcquisitionGeneralFields;

AcquisitionGeneralFields.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setFormData: PropTypes.func.isRequired,
  clientNamesList: PropTypes.oneOfType([PropTypes.array]),
  clientsList: PropTypes.oneOfType([PropTypes.array]),
  handleChange: PropTypes.func.isRequired,
  confirmedClient: PropTypes.bool.isRequired,
  setConfirmedClient: PropTypes.func.isRequired,
  setAlertError: PropTypes.func.isRequired,
  alertError: PropTypes.oneOfType([PropTypes.object]),
  hasTDCAdminPermission: PropTypes.bool.isRequired,
};
