import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Paper,
  Button,
  Chip,
  Collapse,
  Link,
} from '@mui/material';

function TdcProductsCard(props) {
  const {
    logo,
    title,
    upperText,
    lowerText,
    showButton,
    buttonText,
    route,
    deliveryType,
    chipText,
    chipColor,
    deliveryWindow,
    pricing,
    helpDocumentation,
    collapseOption,
  } = props;

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleExpandClick = () => {
    setOpen(!open);
  };

  const handleNavigate = () => {
    const urlRoute = location.pathname + route;
    navigate(urlRoute, {
      replace: false,
      state: { deliveryType },
    });
  };

  return (
    <Box>
      <Paper variant="outlined" sx={{ backgroundColor: '#fff', margin: '10px', padding: '20px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {logo
            && <img src={logo} alt="Product logo" style={{ height: '50px' }} />}
          <h3>{title}</h3>
          {chipText.map((item, i) => (
            <Chip
              label={item}
              sx={{
                margin: '5px', color: 'white', backgroundColor: chipColor[i], fontWeight: '800',
              }}
            />
          ))}
          {/* available for adai aquisition form */}
          {buttonText && showButton
            && (
            <span style={{ marginLeft: 'auto' }}>
              <Button
                onClick={() => handleNavigate()}
                sx={{ marginLeft: 'auto', width: '200px' }}
                variant="contained"
              >
                {buttonText}
              </Button>
            </span>
            )}
        </Box>
        <h4>What is it?</h4>
        <p>{upperText}</p>
        <h4>Who is it best for?</h4>
        <p>{lowerText}</p>
        {collapseOption
          && (
            <Button variant="outlined" sx={{ margin: 'auto', display: 'flex', justifyContent: 'center' }} onClick={handleExpandClick}>
              {open ? 'Show less' : 'Show more'}
            </Button>
          )}
        <Collapse in={open} timeout="auto" unmountOnExit>
          {deliveryWindow
            && (
              <>
                <h4>Delivery Window</h4>
                <p>{deliveryWindow}</p>
              </>
            )}
          {pricing
            && (
              <>
                <h4>Pricing</h4>
                <p>{pricing}</p>
              </>
            )}
          {helpDocumentation.length > 0
            && <h4>Help Documentation</h4>}
          <ul className="productHelpDocsList">
            {helpDocumentation.map((item, i) => (
              <li>
                <Link
                  target="_blank"
                  rel="noreferrer"
                  href={helpDocumentation[i].link}
                >
                  {helpDocumentation[i].text}
                </Link>
              </li>
            ))}
          </ul>
        </Collapse>
      </Paper>
    </Box>
  );
}

export default TdcProductsCard;

TdcProductsCard.propTypes = {
  logo: PropTypes.oneOfType([
    PropTypes.string,
  ]),
  title: PropTypes.string.isRequired,
  upperText: PropTypes.string.isRequired,
  lowerText: PropTypes.string.isRequired,
  showButton: PropTypes.bool,
  buttonText: PropTypes.string,
  route: PropTypes.string,
  deliveryType: PropTypes.string,
  chipText: PropTypes.arrayOf(PropTypes.string),
  chipColor: PropTypes.arrayOf(PropTypes.string),
  deliveryWindow: PropTypes.string,
  pricing: PropTypes.string,
  helpDocumentation: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.string,
  })),
  collapseOption: PropTypes.bool,
};
