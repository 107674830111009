import React, { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Box, InputLabel, MenuItem, FormControl, FormControlLabel,
  FormLabel, Select, RadioGroup, Radio, Tooltip,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PropTypes from 'prop-types';

import ImportHistory from '../components/client/ImportHistory';
import DromoUpload from '../components/DromoUpload';

function TransactionsExclusions(props) {
  const { client } = props;
  // for dromo upload
  const [transactionsCrm, setTransactionsCrm] = useState('actblue'); // alt 'ngp_everyaction'
  const [excludeFlag, setExcludeFlag] = useState(true);

  const handleCrmChange = (event) => {
    setTransactionsCrm(event.target.value);
  };

  const handleExcludeFlagChange = (event) => {
    setExcludeFlag(event.target.value === 'true');
  };

  const importFields = [
    {
      label: 'Recipient ID',
      key: 'recipientId',
      requireMapping: true,
    },
    {
      label: 'Lineitem ID',
      key: 'lineItemId',
      requireMapping: true,
    },
    {
      label: 'Exclude Flag',
      key: 'exclude_flag',
      requireMapping: true,
    },
  ];

  const dromoHook = (records) => records.map((record) => {
    const newRecord = record;

    newRecord.row.exclude_flag = { value: excludeFlag };
    newRecord.row.exclude_flag.info = [
      {
        message: `Exclude Flag set to ${excludeFlag}`,
        level: 'info',
      },
    ];
    return newRecord;
  });

  return (
    <div>
      <Box
        sx={{ mb: 1 }}
      >
        <div className="import-options">
          <div className="exclude-flag-group">
            <FormControl
              sx={{ mx: 1 }}
              focused={false}
            >
              <FormLabel id="exclude-flag-label">
                Exclude Flag
                <Tooltip title="Select whether to exclude or unexclude transactions in this file">
                  <HelpOutlineIcon fontSize="small" />
                </Tooltip>
              </FormLabel>
              <RadioGroup defaultValue="true" aria-label="exclude-flag" name="exclude-flag" onChange={handleExcludeFlagChange}>
                <FormControlLabel
                  value="true"
                  name="exclude-flag-true"
                  control={<Radio />}
                  label="True"
                />
                <FormControlLabel
                  value="false"
                  name="exclude-flag-false"
                  control={<Radio />}
                  label="False"
                />
              </RadioGroup>
            </FormControl>
          </div>

          {client && client === 'emily' && (
          <FormControl
            sx={{ mx: 1 }}
          >
            <InputLabel id="transaction-crm-select">Fundraising CRM</InputLabel>
            <Select
              labelId="transactions-crm-label"
              id="transaction-crm-select"
              value={transactionsCrm}
              label="Fundraising CRM"
              onChange={handleCrmChange}
            >
              <MenuItem value="actblue">Actblue</MenuItem>
              <MenuItem value="ngp_everyaction">NGP</MenuItem>
            </Select>
          </FormControl>
          )}
        </div>
        <DromoUpload
          importFields={importFields}
          importIdentifier={`Transaction Bulk ${excludeFlag === true ? 'Exclusions' : 'Unexclusions'}`}
          allowCustomFields
          userObject={{ id: client, companyName: transactionsCrm }}
          bulkRowHooks={[dromoHook]}
          buttonText="UPLOAD EXCLUSIONS FILE"
        />
      </Box>
      <Box
        sx={{ mt: 3 }}
      >
        <ImportHistory client={client} />
      </Box>
    </div>
  );
}

export default TransactionsExclusions;

TransactionsExclusions.propTypes = {
  client: PropTypes.string,
};
