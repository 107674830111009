/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateClient = /* GraphQL */ `
  subscription OnCreateClient($filter: ModelSubscriptionClientFilterInput) {
    onCreateClient(filter: $filter) {
      client_id
      client_full_name
      jira_project_name
      client_type
      is_active
      is_advantage_member
      is_strategy_partner
      is_mid_level_member
      exclude_from_match_qualifier
      featurize
      people_platform
      action_platform
      transaction_platform
      exhaust_platform
      sms_platform
      transactions_offline_platform
      curate_subscriber
      curate_frequency
      curate_days
      curate_delivery_auto_upload
      suppressed_domains
      actblue_entity_ids
      sfg_surveys_client_ids
      goop_client_ids
      totem_client_ids
      salsa_organization_key
      ngp_everyaction_database_name
      ngp_everyaction_server_name
      salesforce_location
      pardot_location
      hub_source_path
      facebook_account_ids
      facebook_locations
      google_account_ids
      bing_account_ids
      twitter_account_ids
      outbrain_account_ids
      code_dictionary_url
      annelewis_slack_channel
      timezone
      mailchimp_directory
      classy_org_id
      ngp_actblue_manual_codes
      share_cap_max_shares
      share_cap_months
      hubspot_stitch_integration_name
      tatango_client_id
      inbox_monster_rule
      campaign_monitor_location
      pause_data
      donor_threshold
      donor_category
      donor_prop
      email_validation_provider
      multi_member_clients
      multi_member_sms_clients
      multi_member_mid_level_clients
      stw_subaccount_ids
      notes
      is_low_inventory
      limited_model_universe
      fiscal_year_start
      multi_member_required
      multi_member_mid_level_required
      delivery_count
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateClient = /* GraphQL */ `
  subscription OnUpdateClient($filter: ModelSubscriptionClientFilterInput) {
    onUpdateClient(filter: $filter) {
      client_id
      client_full_name
      jira_project_name
      client_type
      is_active
      is_advantage_member
      is_strategy_partner
      is_mid_level_member
      exclude_from_match_qualifier
      featurize
      people_platform
      action_platform
      transaction_platform
      exhaust_platform
      sms_platform
      transactions_offline_platform
      curate_subscriber
      curate_frequency
      curate_days
      curate_delivery_auto_upload
      suppressed_domains
      actblue_entity_ids
      sfg_surveys_client_ids
      goop_client_ids
      totem_client_ids
      salsa_organization_key
      ngp_everyaction_database_name
      ngp_everyaction_server_name
      salesforce_location
      pardot_location
      hub_source_path
      facebook_account_ids
      facebook_locations
      google_account_ids
      bing_account_ids
      twitter_account_ids
      outbrain_account_ids
      code_dictionary_url
      annelewis_slack_channel
      timezone
      mailchimp_directory
      classy_org_id
      ngp_actblue_manual_codes
      share_cap_max_shares
      share_cap_months
      hubspot_stitch_integration_name
      tatango_client_id
      inbox_monster_rule
      campaign_monitor_location
      pause_data
      donor_threshold
      donor_category
      donor_prop
      email_validation_provider
      multi_member_clients
      multi_member_sms_clients
      multi_member_mid_level_clients
      stw_subaccount_ids
      notes
      is_low_inventory
      limited_model_universe
      fiscal_year_start
      multi_member_required
      multi_member_mid_level_required
      delivery_count
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteClient = /* GraphQL */ `
  subscription OnDeleteClient($filter: ModelSubscriptionClientFilterInput) {
    onDeleteClient(filter: $filter) {
      client_id
      client_full_name
      jira_project_name
      client_type
      is_active
      is_advantage_member
      is_strategy_partner
      is_mid_level_member
      exclude_from_match_qualifier
      featurize
      people_platform
      action_platform
      transaction_platform
      exhaust_platform
      sms_platform
      transactions_offline_platform
      curate_subscriber
      curate_frequency
      curate_days
      curate_delivery_auto_upload
      suppressed_domains
      actblue_entity_ids
      sfg_surveys_client_ids
      goop_client_ids
      totem_client_ids
      salsa_organization_key
      ngp_everyaction_database_name
      ngp_everyaction_server_name
      salesforce_location
      pardot_location
      hub_source_path
      facebook_account_ids
      facebook_locations
      google_account_ids
      bing_account_ids
      twitter_account_ids
      outbrain_account_ids
      code_dictionary_url
      annelewis_slack_channel
      timezone
      mailchimp_directory
      classy_org_id
      ngp_actblue_manual_codes
      share_cap_max_shares
      share_cap_months
      hubspot_stitch_integration_name
      tatango_client_id
      inbox_monster_rule
      campaign_monitor_location
      pause_data
      donor_threshold
      donor_category
      donor_prop
      email_validation_provider
      multi_member_clients
      multi_member_sms_clients
      multi_member_mid_level_clients
      stw_subaccount_ids
      notes
      is_low_inventory
      limited_model_universe
      fiscal_year_start
      multi_member_required
      multi_member_mid_level_required
      delivery_count
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTimeStamps = /* GraphQL */ `
  subscription OnCreateTimeStamps(
    $filter: ModelSubscriptionTimeStampsFilterInput
  ) {
    onCreateTimeStamps(filter: $filter) {
      client_id
      column_name
      changed_to
      id
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTimeStamps = /* GraphQL */ `
  subscription OnUpdateTimeStamps(
    $filter: ModelSubscriptionTimeStampsFilterInput
  ) {
    onUpdateTimeStamps(filter: $filter) {
      client_id
      column_name
      changed_to
      id
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTimeStamps = /* GraphQL */ `
  subscription OnDeleteTimeStamps(
    $filter: ModelSubscriptionTimeStampsFilterInput
  ) {
    onDeleteTimeStamps(filter: $filter) {
      client_id
      column_name
      changed_to
      id
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRolePermissions = /* GraphQL */ `
  subscription OnCreateRolePermissions(
    $filter: ModelSubscriptionRolePermissionsFilterInput
  ) {
    onCreateRolePermissions(filter: $filter) {
      id
      role_type
      description
      can_manage_permissions
      can_manage_users
      client_table_column_permissions
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRolePermissions = /* GraphQL */ `
  subscription OnUpdateRolePermissions(
    $filter: ModelSubscriptionRolePermissionsFilterInput
  ) {
    onUpdateRolePermissions(filter: $filter) {
      id
      role_type
      description
      can_manage_permissions
      can_manage_users
      client_table_column_permissions
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRolePermissions = /* GraphQL */ `
  subscription OnDeleteRolePermissions(
    $filter: ModelSubscriptionRolePermissionsFilterInput
  ) {
    onDeleteRolePermissions(filter: $filter) {
      id
      role_type
      description
      can_manage_permissions
      can_manage_users
      client_table_column_permissions
      createdAt
      updatedAt
    }
  }
`;
export const onCreateGroupPermissions = /* GraphQL */ `
  subscription OnCreateGroupPermissions(
    $filter: ModelSubscriptionGroupPermissionsFilterInput
  ) {
    onCreateGroupPermissions(filter: $filter) {
      group_id
      client_id
      id
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateGroupPermissions = /* GraphQL */ `
  subscription OnUpdateGroupPermissions(
    $filter: ModelSubscriptionGroupPermissionsFilterInput
  ) {
    onUpdateGroupPermissions(filter: $filter) {
      group_id
      client_id
      id
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteGroupPermissions = /* GraphQL */ `
  subscription OnDeleteGroupPermissions(
    $filter: ModelSubscriptionGroupPermissionsFilterInput
  ) {
    onDeleteGroupPermissions(filter: $filter) {
      group_id
      client_id
      id
      createdAt
      updatedAt
    }
  }
`;
export const onCreateClientAttributePermissions = /* GraphQL */ `
  subscription OnCreateClientAttributePermissions(
    $filter: ModelSubscriptionClientAttributePermissionsFilterInput
  ) {
    onCreateClientAttributePermissions(filter: $filter) {
      client_permission_id
      client_full_name
      jira_project_name
      client_type
      is_active
      is_advantage_member
      is_strategy_partner
      is_mid_level_member
      exclude_from_match_qualifier
      featurize
      people_platform
      action_platform
      transaction_platform
      exhaust_platform
      sms_platform
      transactions_offline_platform
      curate_subscriber
      curate_frequency
      curate_days
      curate_delivery_auto_upload
      suppressed_domains
      actblue_entity_ids
      sfg_surveys_client_id
      goop_client_id
      salsa_organization_key
      ngp_everyaction_database_name
      ngp_everyaction_server_name
      salesforce_location
      pardot_location
      hub_source_path
      facebook_account_ids
      facebook_locations
      google_account_ids
      bing_account_ids
      twitter_account_ids
      outbrain_account_ids
      code_dictionary_url
      annelewis_slack_channel
      timezone
      mailchimp_directory
      classy_org_id
      ngp_actblue_manual_codes
      share_cap_max_shares
      share_cap_months
      hubspot_stitch_integration_name
      tatango_client_id
      inbox_monster_rule
      campaign_monitor_location
      pause_data
      donor_threshold
      donor_category
      donor_prop
      email_validation_provider
      multi_member_clients
      multi_member_sms_clients
      multi_member_mid_level_clients
      stw_subaccount_ids
      notes
      is_low_inventory
      limited_model_universe
      fiscal_year_start
      multi_member_required
      multi_member_mid_level_required
      delivery_count
      id
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateClientAttributePermissions = /* GraphQL */ `
  subscription OnUpdateClientAttributePermissions(
    $filter: ModelSubscriptionClientAttributePermissionsFilterInput
  ) {
    onUpdateClientAttributePermissions(filter: $filter) {
      client_permission_id
      client_full_name
      jira_project_name
      client_type
      is_active
      is_advantage_member
      is_strategy_partner
      is_mid_level_member
      exclude_from_match_qualifier
      featurize
      people_platform
      action_platform
      transaction_platform
      exhaust_platform
      sms_platform
      transactions_offline_platform
      curate_subscriber
      curate_frequency
      curate_days
      curate_delivery_auto_upload
      suppressed_domains
      actblue_entity_ids
      sfg_surveys_client_id
      goop_client_id
      salsa_organization_key
      ngp_everyaction_database_name
      ngp_everyaction_server_name
      salesforce_location
      pardot_location
      hub_source_path
      facebook_account_ids
      facebook_locations
      google_account_ids
      bing_account_ids
      twitter_account_ids
      outbrain_account_ids
      code_dictionary_url
      annelewis_slack_channel
      timezone
      mailchimp_directory
      classy_org_id
      ngp_actblue_manual_codes
      share_cap_max_shares
      share_cap_months
      hubspot_stitch_integration_name
      tatango_client_id
      inbox_monster_rule
      campaign_monitor_location
      pause_data
      donor_threshold
      donor_category
      donor_prop
      email_validation_provider
      multi_member_clients
      multi_member_sms_clients
      multi_member_mid_level_clients
      stw_subaccount_ids
      notes
      is_low_inventory
      limited_model_universe
      fiscal_year_start
      multi_member_required
      multi_member_mid_level_required
      delivery_count
      id
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteClientAttributePermissions = /* GraphQL */ `
  subscription OnDeleteClientAttributePermissions(
    $filter: ModelSubscriptionClientAttributePermissionsFilterInput
  ) {
    onDeleteClientAttributePermissions(filter: $filter) {
      client_permission_id
      client_full_name
      jira_project_name
      client_type
      is_active
      is_advantage_member
      is_strategy_partner
      is_mid_level_member
      exclude_from_match_qualifier
      featurize
      people_platform
      action_platform
      transaction_platform
      exhaust_platform
      sms_platform
      transactions_offline_platform
      curate_subscriber
      curate_frequency
      curate_days
      curate_delivery_auto_upload
      suppressed_domains
      actblue_entity_ids
      sfg_surveys_client_id
      goop_client_id
      salsa_organization_key
      ngp_everyaction_database_name
      ngp_everyaction_server_name
      salesforce_location
      pardot_location
      hub_source_path
      facebook_account_ids
      facebook_locations
      google_account_ids
      bing_account_ids
      twitter_account_ids
      outbrain_account_ids
      code_dictionary_url
      annelewis_slack_channel
      timezone
      mailchimp_directory
      classy_org_id
      ngp_actblue_manual_codes
      share_cap_max_shares
      share_cap_months
      hubspot_stitch_integration_name
      tatango_client_id
      inbox_monster_rule
      campaign_monitor_location
      pause_data
      donor_threshold
      donor_category
      donor_prop
      email_validation_provider
      multi_member_clients
      multi_member_sms_clients
      multi_member_mid_level_clients
      stw_subaccount_ids
      notes
      is_low_inventory
      limited_model_universe
      fiscal_year_start
      multi_member_required
      multi_member_mid_level_required
      delivery_count
      id
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUsers = /* GraphQL */ `
  subscription OnCreateUsers($filter: ModelSubscriptionUsersFilterInput) {
    onCreateUsers(filter: $filter) {
      id
      firstname
      lastname
      email
      role_id
      allowed_client
      blocked_clients
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUsers = /* GraphQL */ `
  subscription OnUpdateUsers($filter: ModelSubscriptionUsersFilterInput) {
    onUpdateUsers(filter: $filter) {
      id
      firstname
      lastname
      email
      role_id
      allowed_client
      blocked_clients
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUsers = /* GraphQL */ `
  subscription OnDeleteUsers($filter: ModelSubscriptionUsersFilterInput) {
    onDeleteUsers(filter: $filter) {
      id
      firstname
      lastname
      email
      role_id
      allowed_client
      blocked_clients
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSources = /* GraphQL */ `
  subscription OnCreateSources($filter: ModelSubscriptionSourcesFilterInput) {
    onCreateSources(filter: $filter) {
      id
      createdAt
      client_id
      channel_category
      channel
      source
      from_goop
      send_date
      send_cost
      send_size
      codes {
        items {
          id
          createdAt
          client_id
          code
          source
          fixed_cost_media_spend
          fixed_cost_cpa_override
          revenue_category
          audience
          audience_category
          type
          platforms_with_spend
          total_spend
          most_recent_spend
          most_recent_spend_date
          most_recent_transaction_date
          revenue
          transactions
          acquisitions
          most_recent_acquisition_date
          from_goop
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const onUpdateSources = /* GraphQL */ `
  subscription OnUpdateSources($filter: ModelSubscriptionSourcesFilterInput) {
    onUpdateSources(filter: $filter) {
      id
      createdAt
      client_id
      channel_category
      channel
      source
      from_goop
      send_date
      send_cost
      send_size
      codes {
        items {
          id
          createdAt
          client_id
          code
          source
          fixed_cost_media_spend
          fixed_cost_cpa_override
          revenue_category
          audience
          audience_category
          type
          platforms_with_spend
          total_spend
          most_recent_spend
          most_recent_spend_date
          most_recent_transaction_date
          revenue
          transactions
          acquisitions
          most_recent_acquisition_date
          from_goop
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const onDeleteSources = /* GraphQL */ `
  subscription OnDeleteSources($filter: ModelSubscriptionSourcesFilterInput) {
    onDeleteSources(filter: $filter) {
      id
      createdAt
      client_id
      channel_category
      channel
      source
      from_goop
      send_date
      send_cost
      send_size
      codes {
        items {
          id
          createdAt
          client_id
          code
          source
          fixed_cost_media_spend
          fixed_cost_cpa_override
          revenue_category
          audience
          audience_category
          type
          platforms_with_spend
          total_spend
          most_recent_spend
          most_recent_spend_date
          most_recent_transaction_date
          revenue
          transactions
          acquisitions
          most_recent_acquisition_date
          from_goop
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const onCreateCodes = /* GraphQL */ `
  subscription OnCreateCodes($filter: ModelSubscriptionCodesFilterInput) {
    onCreateCodes(filter: $filter) {
      id
      createdAt
      client_id
      code
      source
      fixed_cost_media_spend
      fixed_cost_cpa_override
      revenue_category
      audience
      audience_category
      type
      platforms_with_spend
      total_spend
      most_recent_spend
      most_recent_spend_date
      most_recent_transaction_date
      revenue
      transactions
      acquisitions
      most_recent_acquisition_date
      from_goop
      updatedAt
    }
  }
`;
export const onUpdateCodes = /* GraphQL */ `
  subscription OnUpdateCodes($filter: ModelSubscriptionCodesFilterInput) {
    onUpdateCodes(filter: $filter) {
      id
      createdAt
      client_id
      code
      source
      fixed_cost_media_spend
      fixed_cost_cpa_override
      revenue_category
      audience
      audience_category
      type
      platforms_with_spend
      total_spend
      most_recent_spend
      most_recent_spend_date
      most_recent_transaction_date
      revenue
      transactions
      acquisitions
      most_recent_acquisition_date
      from_goop
      updatedAt
    }
  }
`;
export const onDeleteCodes = /* GraphQL */ `
  subscription OnDeleteCodes($filter: ModelSubscriptionCodesFilterInput) {
    onDeleteCodes(filter: $filter) {
      id
      createdAt
      client_id
      code
      source
      fixed_cost_media_spend
      fixed_cost_cpa_override
      revenue_category
      audience
      audience_category
      type
      platforms_with_spend
      total_spend
      most_recent_spend
      most_recent_spend_date
      most_recent_transaction_date
      revenue
      transactions
      acquisitions
      most_recent_acquisition_date
      from_goop
      updatedAt
    }
  }
`;
export const onCreateTransactions = /* GraphQL */ `
  subscription OnCreateTransactions(
    $filter: ModelSubscriptionTransactionsFilterInput
  ) {
    onCreateTransactions(filter: $filter) {
      id
      client_id
      email
      code
      amount
      transaction_dt
      recurring_period
      recurrence
      recurring_type
      refund_flag
      exclude_flag
      exclusion_updated_dt
      foreign_platform
      foreign_client_key
      foreign_transaction_key
      mobile_flag
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTransactions = /* GraphQL */ `
  subscription OnUpdateTransactions(
    $filter: ModelSubscriptionTransactionsFilterInput
  ) {
    onUpdateTransactions(filter: $filter) {
      id
      client_id
      email
      code
      amount
      transaction_dt
      recurring_period
      recurrence
      recurring_type
      refund_flag
      exclude_flag
      exclusion_updated_dt
      foreign_platform
      foreign_client_key
      foreign_transaction_key
      mobile_flag
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTransactions = /* GraphQL */ `
  subscription OnDeleteTransactions(
    $filter: ModelSubscriptionTransactionsFilterInput
  ) {
    onDeleteTransactions(filter: $filter) {
      id
      client_id
      email
      code
      amount
      transaction_dt
      recurring_period
      recurrence
      recurring_type
      refund_flag
      exclude_flag
      exclusion_updated_dt
      foreign_platform
      foreign_client_key
      foreign_transaction_key
      mobile_flag
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateInsightsAcqDeliveries = /* GraphQL */ `
  subscription OnCreateInsightsAcqDeliveries(
    $filter: ModelSubscriptionInsightsAcqDeliveriesFilterInput
  ) {
    onCreateInsightsAcqDeliveries(filter: $filter) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateInsightsAcqDeliveries = /* GraphQL */ `
  subscription OnUpdateInsightsAcqDeliveries(
    $filter: ModelSubscriptionInsightsAcqDeliveriesFilterInput
  ) {
    onUpdateInsightsAcqDeliveries(filter: $filter) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteInsightsAcqDeliveries = /* GraphQL */ `
  subscription OnDeleteInsightsAcqDeliveries(
    $filter: ModelSubscriptionInsightsAcqDeliveriesFilterInput
  ) {
    onDeleteInsightsAcqDeliveries(filter: $filter) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateInsightsAcqDeliveriesCohort = /* GraphQL */ `
  subscription OnCreateInsightsAcqDeliveriesCohort(
    $filter: ModelSubscriptionInsightsAcqDeliveriesCohortFilterInput
  ) {
    onCreateInsightsAcqDeliveriesCohort(filter: $filter) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      cohort
      cohort_name
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateInsightsAcqDeliveriesCohort = /* GraphQL */ `
  subscription OnUpdateInsightsAcqDeliveriesCohort(
    $filter: ModelSubscriptionInsightsAcqDeliveriesCohortFilterInput
  ) {
    onUpdateInsightsAcqDeliveriesCohort(filter: $filter) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      cohort
      cohort_name
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteInsightsAcqDeliveriesCohort = /* GraphQL */ `
  subscription OnDeleteInsightsAcqDeliveriesCohort(
    $filter: ModelSubscriptionInsightsAcqDeliveriesCohortFilterInput
  ) {
    onDeleteInsightsAcqDeliveriesCohort(filter: $filter) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      cohort
      cohort_name
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateInsightsAdvDeliveries = /* GraphQL */ `
  subscription OnCreateInsightsAdvDeliveries(
    $filter: ModelSubscriptionInsightsAdvDeliveriesFilterInput
  ) {
    onCreateInsightsAdvDeliveries(filter: $filter) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateInsightsAdvDeliveries = /* GraphQL */ `
  subscription OnUpdateInsightsAdvDeliveries(
    $filter: ModelSubscriptionInsightsAdvDeliveriesFilterInput
  ) {
    onUpdateInsightsAdvDeliveries(filter: $filter) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteInsightsAdvDeliveries = /* GraphQL */ `
  subscription OnDeleteInsightsAdvDeliveries(
    $filter: ModelSubscriptionInsightsAdvDeliveriesFilterInput
  ) {
    onDeleteInsightsAdvDeliveries(filter: $filter) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateInsightsAdvDeliveriesCohort = /* GraphQL */ `
  subscription OnCreateInsightsAdvDeliveriesCohort(
    $filter: ModelSubscriptionInsightsAdvDeliveriesCohortFilterInput
  ) {
    onCreateInsightsAdvDeliveriesCohort(filter: $filter) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      cohort
      cohort_name
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateInsightsAdvDeliveriesCohort = /* GraphQL */ `
  subscription OnUpdateInsightsAdvDeliveriesCohort(
    $filter: ModelSubscriptionInsightsAdvDeliveriesCohortFilterInput
  ) {
    onUpdateInsightsAdvDeliveriesCohort(filter: $filter) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      cohort
      cohort_name
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteInsightsAdvDeliveriesCohort = /* GraphQL */ `
  subscription OnDeleteInsightsAdvDeliveriesCohort(
    $filter: ModelSubscriptionInsightsAdvDeliveriesCohortFilterInput
  ) {
    onDeleteInsightsAdvDeliveriesCohort(filter: $filter) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      cohort
      cohort_name
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateInsightsReacDeliveries = /* GraphQL */ `
  subscription OnCreateInsightsReacDeliveries(
    $filter: ModelSubscriptionInsightsReacDeliveriesFilterInput
  ) {
    onCreateInsightsReacDeliveries(filter: $filter) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateInsightsReacDeliveries = /* GraphQL */ `
  subscription OnUpdateInsightsReacDeliveries(
    $filter: ModelSubscriptionInsightsReacDeliveriesFilterInput
  ) {
    onUpdateInsightsReacDeliveries(filter: $filter) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteInsightsReacDeliveries = /* GraphQL */ `
  subscription OnDeleteInsightsReacDeliveries(
    $filter: ModelSubscriptionInsightsReacDeliveriesFilterInput
  ) {
    onDeleteInsightsReacDeliveries(filter: $filter) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateInsightsReacDeliveriesCohort = /* GraphQL */ `
  subscription OnCreateInsightsReacDeliveriesCohort(
    $filter: ModelSubscriptionInsightsReacDeliveriesCohortFilterInput
  ) {
    onCreateInsightsReacDeliveriesCohort(filter: $filter) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      cohort
      cohort_name
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      recurring_flag
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateInsightsReacDeliveriesCohort = /* GraphQL */ `
  subscription OnUpdateInsightsReacDeliveriesCohort(
    $filter: ModelSubscriptionInsightsReacDeliveriesCohortFilterInput
  ) {
    onUpdateInsightsReacDeliveriesCohort(filter: $filter) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      cohort
      cohort_name
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      recurring_flag
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteInsightsReacDeliveriesCohort = /* GraphQL */ `
  subscription OnDeleteInsightsReacDeliveriesCohort(
    $filter: ModelSubscriptionInsightsReacDeliveriesCohortFilterInput
  ) {
    onDeleteInsightsReacDeliveriesCohort(filter: $filter) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      cohort
      cohort_name
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      recurring_flag
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDeliveries = /* GraphQL */ `
  subscription OnCreateDeliveries(
    $filter: ModelSubscriptionDeliveriesFilterInput
  ) {
    onCreateDeliveries(filter: $filter) {
      id
      client_id
      cost_per_name
      cost_per_order
      number_of_free_names
      reason
      approved_by
      invoice_number
      payment_received
      databricks_job_id
      delivery_config
      delivery_date
      delivery_id
      delivery_type
      file_uploads
      pm_comments
      status
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDeliveries = /* GraphQL */ `
  subscription OnUpdateDeliveries(
    $filter: ModelSubscriptionDeliveriesFilterInput
  ) {
    onUpdateDeliveries(filter: $filter) {
      id
      client_id
      cost_per_name
      cost_per_order
      number_of_free_names
      reason
      approved_by
      invoice_number
      payment_received
      databricks_job_id
      delivery_config
      delivery_date
      delivery_id
      delivery_type
      file_uploads
      pm_comments
      status
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDeliveries = /* GraphQL */ `
  subscription OnDeleteDeliveries(
    $filter: ModelSubscriptionDeliveriesFilterInput
  ) {
    onDeleteDeliveries(filter: $filter) {
      id
      client_id
      cost_per_name
      cost_per_order
      number_of_free_names
      reason
      approved_by
      invoice_number
      payment_received
      databricks_job_id
      delivery_config
      delivery_date
      delivery_id
      delivery_type
      file_uploads
      pm_comments
      status
      createdAt
      updatedAt
    }
  }
`;
