import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';

import { Button } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Snackbar from '@mui/material/Snackbar';
import { Star, StarBorder } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import { logReactErrBoundaryError, clientSettingsUpdater } from '../utils';
import APIWrapper from '../utils/graphqlwrapper';
import logger from '../utils/logger';
import { alphabetizeCols } from '../utils/tableHelpers';

import GeneralTab from '../components/client/GeneralTab';
import DataIngest from '../components/client/DataIngest';
import AdAITab from '../components/client/AdAITab';
import OtherInfoTab from '../components/client/OtherInfoTab';
import Alert from '../utils/Alert';
import TabPanel from '../components/TabPanel';
import FallbackOnError from '../components/FallbackOnError';
import {
  addFavoriteClient,
  checkIfFavorite,
  getFavoriteClients,
  removeFavoriteClient,
} from '../utils/favsAndRecentClients';
import AppContext from '../context';
import { defaultStructure } from '../utils/settingConstants';

function FavoriteCheckbox({ isChecked, ...props }) {
  if (typeof isChecked === 'boolean') {
    return (
      <Checkbox
        icon={<StarBorder />}
        checkedIcon={<Star />}
        checked={isChecked}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    );
  }

  return (
    <Checkbox
      icon={<StarBorder />}
      checkedIcon={<Star />}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

FavoriteCheckbox.propTypes = {
  isChecked: PropTypes.bool,
};

function ClientInfo(props) {
  const { authenticated, setShouldLogOut, setFavClients } = useContext(AppContext);
  // prop for new client
  const param = useParams();
  const {
    hasTDCAdminPermission,
    hasDataEngineerPermission,
    hasAnalystPermission,
    setHeader,
    isNew,
  } = props;
  const navigate = useNavigate();

  const [data, setData] = useState();
  const [timeStamps, setTimeStamps] = useState();
  const [alertMessage, setAlertMessage] = useState();
  const [structure, setStructure] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [permissions, setPermissions] = useState({});
  const [tab, setTab] = useState(0);
  const [isFormValid, setIsFormValid] = useState(true);
  const [createdAt, setCreatedAt] = useState(null);
  const pageSetter = setHeader;
  let formatMessage;

  // used for Snackbar
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
    setSnackMessage('');
  };

  const cleanData = (apidata) => {
    const clean = apidata;
    ['updatedAt', 'createdAt', '_lastChangedAt', '_deleted'].forEach((item) => {
      if (clean[item]) delete clean[item];
    });
    // if the platform fields are not yet set, set it as an empty array.
    [
      'transaction_platform',
      'people_platform',
      'action_platform',
      'exhaust_platform',
      'sms_platform',
      'transactions_offline_platform',
    ].forEach((item) => {
      if (clean[item] === null) clean[item] = [];
    });
    return clean;
  };

  const getClientInfo = async (clientId) => {
    const result = await APIWrapper.queryApi({ query: 'getClient' }, setShouldLogOut, {
      client_id: clientId,
    })
      .then((resp) => resp)
      .catch((err) => logger.error(err));
    return result;
  };

  const getAdAiClients = async () => {
    const result = await APIWrapper.queryApi(
      { query: 'listClients' },
      setShouldLogOut,
      {
        filter: { is_advantage_member: { eq: true } },
        limit: 1000,
      },
    )
      .then((resp) => resp)
      .catch((err) => logger.error(err));
    return result;
  };

  // get timestamps
  const timestampfields = ['featurize', 'is_active', 'is_advantage_member'];
  const getTimeStampInfo = async (clientId) => {
    // eslint-disable-next-line max-len
    const timestampqueries = timestampfields.map((field) => APIWrapper.queryApi({ query: 'searchTimeStamps' }, false, {
      filter: { client_id: { eq: clientId }, column_name: { eq: field } },
      sort: { direction: 'desc', field: 'createdAt' },
      limit: 1,
    })
      .then((resp) => resp)
      .catch((err) => logger.error(err)));

    const results = await Promise.allSettled(timestampqueries).catch((err) => {
      logger.error(err);
    });
    const formattedResults = {};
    results.forEach((r, i) => {
      if (r.value) {
        // returns single obj with changed time
        const result = r.value.data[0][0];
        formattedResults[timestampfields[i]] = result;
      }
    });

    setTimeStamps(formattedResults);
  };

  useEffect(() => {
    // if we're making a new client set the header but don't fetch data
    if (isNew) {
      setData({});
      if (typeof pageSetter === 'function') {
        document.title = 'MissionPortal | New Client';
        pageSetter(['New Client']);
      }
    } else {
      document.title = `MissionPortal | ${param.client}`;
      getClientInfo(param.client)
        .then((resp) => {
          setData(resp.data[0][0]);
          setCreatedAt(resp.data[0][0].createdAt);
        })
        .catch((err) => {
          logger.error(err);
        });
    }
    const adAiClientNames = [''];
    defaultStructure.multi_member_clients = ['loading...'];
    defaultStructure.multi_member_sms_clients = ['loading...'];
    defaultStructure.multi_member_mid_level_clients = ['loading...'];
    getAdAiClients().then((resp) => {
      // alphabetize client_full_name in appsync response for multi-member clients dropdowns
      const alphabetizedCols = alphabetizeCols(resp.data[0], 'client_full_name');
      // set the structure for the multi-member clients dropdowns
      defaultStructure.multi_member_clients = adAiClientNames;
      defaultStructure.multi_member_sms_clients = adAiClientNames;
      defaultStructure.multi_member_mid_level_clients = adAiClientNames;

      alphabetizedCols.forEach((client) => {
        adAiClientNames.push(client.client_full_name);
      });
    });

    // get client timestamp information for specific fields
    getTimeStampInfo(param.client);
    // get structure of data (ie options for dropdowns and such)
    setStructure(defaultStructure);
    // need to validate permissions prop for nested components and it is looking in this file.
    setPermissions({});
    // get user permission info (about what they can edit here)
  }, []);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const updateData = (attribute, value, label) => {
    const updated = clientSettingsUpdater(data, attribute, value, label);
    logger.debug(updated);
    setData({ ...updated });
  };

  const updateValidity = (bool) => {
    if (bool === false) {
      setAlertMessage({
        severity: 'error',
        message: 'Please ensure all changed fields have valid inputs.',
      });
    } else {
      setAlertMessage();
    }
    setIsFormValid(bool);
  };

  const validateForm = () => {
    const required = [
      'client_full_name',
      'client_id',
      'jira_project_name',
      'client_type',
    ];
    const fields = [
      'client full name',
      'client code',
      'Jira project name',
      'client type',
    ];
    const missing = [];
    required.forEach((att, i) => {
      if (!(att in data)) {
        missing.push(fields[i]);
      }
    });
    return missing;
  };

  const onSubmit = async () => {
    if (authenticated) {
      const missing = validateForm();
      if (missing.length > 0) {
        formatMessage = missing.join(', ');
        setAlertMessage({
          severity: 'error',
          message:
            `Please fill out all required fields. Missing ${formatMessage}.`,
        });
        // eslint-disable-line
        return;
      }
      if (!isNew) {
        // update the current client
        const cleanedData = cleanData(data);
        APIWrapper.queryApi(
          { mutation: 'updateClient' },
          setShouldLogOut,
          { input: cleanedData },
        )
          .then((resp) => {
            logger.debug(resp.data[0]);
            setSnackMessage('Client information updated.');
            setOpenSnackbar(true);
          })
          .catch((err) => {
            logger.error(err);
            setSnackMessage('Error updating client information.');
            setOpenSnackbar(true);
          });
      } else {
        // check that client we want to create does not exist in database already
        getClientInfo(data.client_id)
          .then((client) => {
            if (client.data[0].length === 0) {
              // new client
              APIWrapper.queryApi(
                { mutation: 'createClient' },
                setShouldLogOut,
                { input: data },
              )
                .then((resp) => {
                  const newClient = resp.data[0];
                  const cleanedClient = cleanData(newClient[0]);
                  setData(cleanedClient);
                  navigate(`/client/${cleanedClient.client_id}`, {
                    replace: false,
                    state: { client: cleanedClient },
                  });
                })
                .catch((err) => {
                  logger.error(err);
                });
            } else {
              setAlertMessage({
                severity: 'error',
                message: `Client ${data.client_id} already exists.`,
              });
            }
          })
          .catch((err) => {
            logger.error(err);
          });
      }
    }

    // timestamp lambda takes a second or two to update the table so wait then refetch timestamps
    setTimeout(() => {
      getTimeStampInfo(data.client_id);
    }, 5000);
  };

  const updateFavorites = () => {
    const checkFavorited = checkIfFavorite(param.client);
    if (checkFavorited) {
      removeFavoriteClient(param.client);
      setFavClients(getFavoriteClients());
    } else {
      addFavoriteClient(param.client);
      setFavClients(getFavoriteClients());
    }
  };

  return (
    data && (
      <ErrorBoundary
        FallbackComponent={FallbackOnError}
        onError={logReactErrBoundaryError}
        onReset={(details) => {
          logger.info('details', details);
          // Reset the state of your app so the error doesn't happen again
        }}
      >
        <div>
          <header className="App-header" id="clientinfo-header">
            <div id="clientinfo-link">
              <h2>
                {isNew
                  ? 'Create Client'
                  : `Manage ${data.client_id.toUpperCase()}`}
              </h2>
              {!isNew && (
                <FavoriteCheckbox
                  checked={checkIfFavorite(param.client)}
                  onClick={updateFavorites}
                />
              )}
            </div>
            {(hasTDCAdminPermission || hasDataEngineerPermission || hasAnalystPermission) && (
              <div id="submit-clientinfo">
                <Button size="small" variant="contained" onClick={onSubmit} disabled={!isFormValid}>
                  Submit
                </Button>
              </div>
            )}
          </header>
          <Box sx={{ width: '100%' }}>
            <>
              <div id="alert-message">
                {alertMessage
                  && (
                    <Alert
                      alert={alertMessage}
                    />
                  )}
              </div>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                {
                  (!hasTDCAdminPermission
                    && !hasDataEngineerPermission
                    && !hasAnalystPermission) && (
                    <Tabs
                      value={tab}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab label="General" />
                      <Tooltip title="You do not have permission to edit this tab" placement="bottom">
                        <div>
                          <Tab label="Data Ingest" disabled />
                        </div>
                      </Tooltip>
                      <Tooltip title="You do not have permission to edit this tab" placement="bottom">
                        <div>
                          <Tab label="Advantage AI" disabled />
                        </div>
                      </Tooltip>
                      <Tooltip title="You do not have permission to edit this tab" placement="bottom">
                        <div>
                          <Tab label="Other" disabled />
                        </div>
                      </Tooltip>
                    </Tabs>
                  )
                }
                {(hasDataEngineerPermission && !hasTDCAdminPermission && !hasAnalystPermission) && (
                  <Tabs
                    value={tab}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="General" />
                    <Tab label="Data Ingest" />
                    <Tooltip title="You do not have permission to edit this tab" placement="bottom">
                      <div>
                        <Tab label="Advantage AI" disabled />
                      </div>
                    </Tooltip>
                    <Tooltip title="You do not have permission to edit this tab" placement="bottom">
                      <div>
                        <Tab label="Other" disabled />
                      </div>
                    </Tooltip>
                  </Tabs>
                )}
                {(!hasDataEngineerPermission && !hasTDCAdminPermission && hasAnalystPermission) && (
                  <Tabs
                    value={tab}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="General" />
                    <Tooltip title="You do not have permission to edit this tab" placement="bottom">
                      <div>
                        <Tab label="Data Ingest" disabled />
                      </div>
                    </Tooltip>
                    <Tooltip title="You do not have permission to edit this tab" placement="bottom">
                      <div>
                        <Tab label="Advantage AI" disabled />
                      </div>
                    </Tooltip>
                    <Tab label="Other" />
                  </Tabs>
                )}
                {hasTDCAdminPermission && (
                  <Tabs
                    value={tab}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="General" />
                    <Tab label="Data Ingest" />
                    <Tab label="Advantage AI" />
                    <Tab label="Other" />
                  </Tabs>
                )}
              </Box>
              <>
                <TabPanel label="General" value={tab} index={0}>
                  <GeneralTab
                    setFunction={updateData}
                    data={data}
                    structure={structure}
                    key={data}
                    permissions={permissions}
                    isNew={isNew}
                    hasTDCAdminPermission={hasTDCAdminPermission}
                  />
                </TabPanel>
                <TabPanel value={tab} index={1}>
                  <DataIngest
                    validateFunc={updateValidity}
                    setFunction={updateData}
                    data={data}
                    structure={structure}
                    key={data}
                    permissions={permissions}
                    hasTDCAdminPermission={hasTDCAdminPermission}
                    timeStamps={timeStamps}
                    createdAt={createdAt}
                  />
                </TabPanel>
                <TabPanel value={tab} index={2}>
                  <AdAITab
                    validateFunc={updateValidity}
                    setFunction={updateData}
                    data={data}
                    structure={structure}
                    key={data}
                    hasTDCAdminPermission={hasTDCAdminPermission}
                    permissions={permissions}
                    timeStamps={timeStamps}
                    createdAt={createdAt}
                  />
                </TabPanel>
                <TabPanel value={tab} index={3}>
                  <OtherInfoTab
                    setFunction={updateData}
                    data={data}
                    structure={structure}
                    key={data}
                    permissions={permissions}
                  />
                </TabPanel>
              </>
            </>
          </Box>
        </div>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={handleClose}
          message={snackMessage}
        />
      </ErrorBoundary>
    )
  );
}

export default ClientInfo;

ClientInfo.propTypes = {
  hasTDCAdminPermission: PropTypes.bool.isRequired,
  hasDataEngineerPermission: PropTypes.bool.isRequired,
  hasAnalystPermission: PropTypes.bool.isRequired,
  isNew: PropTypes.bool,
  setHeader: PropTypes.func,
};
