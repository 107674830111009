import logger from './logger';
import { valueFormatters } from './schemaInfo';
import { arrayFields, intFields, resetFieldValues } from './settingConstants';

/* given an array of objects [{id: 'xxx', ...}, ...] returns array index where
object id matches given id (or -1 if no match found) */
const arrayIdxForMatchingId = (arr, id) => {
  for (let i = 0; i < arr.length; i += 1) {
    if (arr[i].id === id) {
      return i;
    }
  }
  return -1;
};

// input 2 arrays (elements should be primitives)
// returns true if the arrays are equal (same length, same elements)
// where order does not matter
const areArraysEqual = (ar1, ar2) => {
  // check length
  if (ar1.length !== ar2.length) {
    return false;
  }

  // sort and compare elements
  const sortedAr1 = ar1.slice(0);
  sortedAr1.sort();
  const sortedAr2 = ar2.slice(0);
  sortedAr2.sort();

  for (let i = 0; i < sortedAr1.length; i += 1) {
    if (sortedAr1[i] !== sortedAr2[i]) {
      return false;
    }
  }
  return true;
};

// TODO 110223 handle case when value is an array of primitives
/* given 2 objects returns boolean whether they pass deep equals test (key/value pairs match
exactly) assumes all values for all object keys are primitives */
const deepEqualsObj = (obj1, obj2) => {
  const obj1Keys = Object.keys(obj1);
  const obj2Keys = Object.keys(obj2);

  if (obj1Keys.length !== obj2Keys.length) {
    return false;
  }
  for (let i = 0; i < obj1Keys.length; i += 1) {
    const k = obj1Keys[i];
    // check if both values are arrays
    // current usecase is where column.format is an array of strings
    if (Array.isArray(obj1[k]) && Array.isArray(obj2[k]) && !areArraysEqual(obj1[k], obj2[k])) {
      return false;
    }
    if (typeof obj1[k] === 'object' && typeof obj2[k] === 'object') {
      if (deepEqualsObj(obj1[k], obj2[k]) === false) {
        return false;
      }
    } else if (obj1[k] !== obj2[k]) {
      return false;
    }
  }
  return true;
};

// given an id value, returns the index for its element in an array
// rows is an array of objects with a key, id
// if the id cannot be found, returns -1
const getRowIdxForId = (id, rows) => {
  for (let i = 0; i < rows.length; i += 1) {
    if (id === rows[i].id) {
      return i;
    }
  }
  return -1;
};

// get an object from Ar of objects
// {id1: array_el_1, id2: array_el_2, etc}
// assumes unique id's
const getObjFromAr = (ar) => {
  if (ar.length === 0) {
    return {};
  }
  const obj = ar.reduce((accum, cur) => {
    const k = cur.id;
    const copyAccum = { ...accum };
    copyAccum[k] = cur;
    return copyAccum;
  }, {});
  return obj;
};

// get an object from Ar of objects
// {id1: array_el_1, id2: array_el_2, etc}
// assumes unique id's
// used when the key is not id (didn't have time to test refactor of the previous function)
const getObjFromArWithKey = (ar, key) => {
  if (ar.length === 0) {
    return {};
  }
  const obj = ar.reduce((accum, cur) => {
    const k = key ? cur[key] : cur.id;
    const copyAccum = { ...accum };
    copyAccum[k] = cur;
    return copyAccum;
  }, {});
  return obj;
};

// given an object of objects, returns an array of those objects
const getArFromObj = (obj) => {
  if (Object.keys(obj).length === 0) {
    return [];
  }
  const ar = Object.keys(obj).map((k) => obj[k]);
  return ar;
};

// const used by helper functions
const tableNameTablePrefObj = {
  clientlist: {
    filter: 'client-filter',
    column: 'client-column',
    'column-visibility': 'client-column-visibility',
    sort: 'client-sort',
  },
  sources: {
    filter: 'sources-filter',
    column: 'sources-column',
    'column-visibility': 'sources-column-visibility',
    sort: 'sources-sort',
  },
  codes: {
    filter: 'codes-filter',
    column: 'codes-column',
    'column-visibility': 'codes-column-visibility',
    sort: 'codes-sort',
  },
  'codes-adv': {
    filter: 'codes-adv-filter',
    column: 'codes-adv-column',
  },
  'sources-adv': {
    filter: 'sources-adv-filter',
    column: 'sources-adv-column',
  },
  sumbysource: {
    filter: 'sumbysource-filter',
    column: 'sumbysource-column',
  },
  'transactions-adv': {
    filter: 'transactions-adv-filter',
    column: 'transactions-adv-column',
  },
  'insights-searchInsightsAcqDeliveries': {
    filter: 'insights-searchInsightsAcqDeliveries-filter',
    column: 'insights-searchInsightsAcqDeliveries-column',
    'column-visibility': 'insights-searchInsightsAcqDeliveries-column-visibility',
    sort: 'insights-searchInsightsAcqDeliveries-sort',
  },
  'insights-searchInsightsAcqDeliveriesCohorts': {
    filter: 'insights-searchInsightsAcqDeliveriesCohorts-filter',
    column: 'insights-searchInsightsAcqDeliveriesCohorts-column',
    'column-visibility': 'insights-searchInsightsAcqDeliveriesCohorts-column-visibility',
    sort: 'insights-searchInsightsAcqDeliveriesCohorts-sort',
  },
  'insights-searchInsightsAdvDeliveries': {
    filter: 'insights-searchInsightsAdvDeliveries-filter',
    column: 'insights-searchInsightsAdvDeliveries-column',
    'column-visibility': 'insights-searchInsightsAdvDeliveries-column-visibility',
    sort: 'insights-searchInsightsAdvDeliveries-sort',
  },
  'insights-searchInsightsAdvDeliveriesCohorts': {
    filter: 'insights-searchInsightsAdvDeliveriesCohorts-filter',
    column: 'insights-searchInsightsAdvDeliveriesCohorts-column',
    'column-visibility': 'insights-searchInsightsAdvDeliveriesCohorts-column-visibility',
    sort: 'insights-searchInsightsAdvDeliveriesCohorts-sort',
  },
  'insights-searchInsightsReacDeliveries': {
    filter: 'insights-searchInsightsReacDeliveries-filter',
    column: 'insights-searchInsightsReacDeliveries-column',
    'column-visibility': 'insights-searchInsightsReacDeliveries-column-visibility',
    sort: 'insights-searchInsightsReacDeliveries-sort',
  },
  'insights-searchInsightsReacDeliveriesCohorts': {
    filter: 'insights-searchInsightsReacDeliveriesCohorts-filter',
    column: 'insights-searchInsightsReacDeliveriesCohorts-column',
    'column-visibility': 'insights-searchInsightsReacDeliveriesCohorts-column-visibility',
    sort: 'insights-searchInsightsReacDeliveriesCohorts-sort',
  },
  importhistory: {
    filter: 'importhistory-filter',
    column: 'importhistory-column',
    'column-visibility': 'importhistory-column-visibility',
    sort: 'importhistory-sort',
  },
  'importhistory-adv': {
    filter: 'importhistory-adv-filter',
    column: 'importhistory-adv-column',
  },
};

const tablePreferenceKeySuffix = '-tableConfiguration';

// helper to get localStorage val for table preferences (filter, col order, sort order)
const getLocalStorageTablePref = (table, preference) => {
  try {
    const k = `${tableNameTablePrefObj[table][preference]}${tablePreferenceKeySuffix}`;
    const v = localStorage.getItem(k);
    try {
      const allCols = JSON.parse(v);
      // if column preference, add valueFormatter function based on formatterName
      if (preference === 'column' && allCols) {
        allCols.forEach((col, i) => {
          if (col.formatterName) {
            if (valueFormatters[col.formatterName]) {
              allCols[i].valueFormatter = valueFormatters[col.formatterName];
            }
          }
        });
        return allCols;
      }
    } catch (err) {
      logger.error(err);
    }
    return false;
  } catch (err) {
    logger.error(err);
    return false;
  }
};

// helper to set localStorage val for table preferences (filter, col order, sort order)
const setLocalStorageTablePref = (table, preference, newValue) => {
  try {
    const k = `${tableNameTablePrefObj[table][preference]}${tablePreferenceKeySuffix}`;
    const newItemValue = JSON.stringify(newValue);
    localStorage.setItem(k, newItemValue);
  } catch (error) {
    logger.error(`localStorage for table preferences error: ${error}`);
  }
};

// helper that sets localStorage val for table/preference and calls component state
// setter for controlled table preference model
// table: client, sources, codes
// preference: filter, column, 'column-visibility', sort
// newValue: new preference model object
// setFilterModelFunc (optional): setter (defined with useState) to update the preference model
// object (used by mui in react state)
const updateTablePreferenceLocalStorage = (table, preference, newValue, setFilterModelFunc) => {
  if (table !== null) {
    setLocalStorageTablePref(table, preference, newValue);
    if (setFilterModelFunc) {
      setFilterModelFunc(newValue);
    }
  }
};

const logReactErrBoundaryError = (error, info) => {
  logger.error('error', error.message);
  logger.info('info', info);
  // Do something with the error, e.g. log to an external API
};

// compare 2 lists of objects (used for comparing table preference objects for columns)
// returns false if lists are not equal (deep equals)
// handle case where the lists contain the same objects but in different order
// currently used to compare the mui table column definition (within utils/schemaLables)
// with the table preference column definition (within localStorage) to tell when columns have
// changed by dev defini tion and need to overwrite the old column definition in localStorage
const diffListOfObjects = (list1, list2) => {
  // check length
  if (list1.length !== list2.length) {
    return false;
  }

  const objList1 = getObjFromArWithKey(list1, 'field');
  const objList2 = getObjFromArWithKey(list2, 'field');

  // check deepequals objList1 and objList2
  // only checking for values of primitives or arrays of strings currently
  if (!deepEqualsObj(objList1, objList2)) {
    return false;
  }
  return true;
};

/*
 * Gets the current user preference for column order from localStorage
 *    and updates react state (if nothing found in localStorage, sets with the default
 *    column definitions); compares the latest column definitions for the table with
 *    localStorage values and if they are different, updates localStorage with the latest
 *    ... right now logic is partly specific to column order preference so needs refactor
 *    to use for other preferences
 * @param tableName: string; passed into helper functions that retrieve localStorage values
 *    and/or set localStorage values and set react state
 * @param preferenceName: string; passed into helper functions that retrieve localStorage values
 *    and/or set localStorage values and set react state
 * @param latestColDefinitions: array of objects; latest column definitions for table (add,
 *    remove, reorder, and mui column settings like width)
 * @param setOrderedColumns (optional): function; part of react state (used to track current user
 *    pref for column order); not getting used by insights table, not sure how this impacts column
 *    order tracking there
 * @return no return value just updates localStorage and react state
 */

const tableColumnPreferenceLoader = (
  tableName,
  preferenceName,
  latestColDefinitions,
  setOrderedColumns,
) => {
  const tableColumnsLocalStor = getLocalStorageTablePref(tableName, preferenceName);
  const isTableColsDefUpdated = !diffListOfObjects(latestColDefinitions, tableColumnsLocalStor);

  if (!tableColumnsLocalStor || isTableColsDefUpdated) {
    updateTablePreferenceLocalStorage(tableName, preferenceName, latestColDefinitions);
  } else if (setOrderedColumns !== undefined) {
    // insights tables is not using a setOrderedColumns function so this is error handling
    setOrderedColumns(tableColumnsLocalStor);
  }
};

const formatPercentFloat = (value) => {
  /**
   * return value as decimal percent
   * @param {string} value - the input value
   * @returns {int} value - the new value
   */
  let returnValue;
  if (parseFloat(value) % 1 === 0 || value === '') {
    returnValue = value === '' ? null : parseFloat(value / 100).toFixed(2);
  } else {
    returnValue = parseFloat(value);
  }
  return returnValue;
};

const formatInt = (value) => {
  /**
   * return value as integer
   * @param {string} value - the input value
   * @returns {int} value - the new value
   */
  let returnValue;
  const isANumber = !Number.isNaN(parseFloat(value));
  if (isANumber || value === '') {
    returnValue = value === '' ? null : Math.trunc(value);
  }
  return returnValue;
};

const clientSettingsUpdater = (data, attribute, value, label) => {
  /**
   * Updates the client settings object with the new value
   * @param {object} data - the client settings object
   * @param {string} attribute - the attribute to update
   * @param {string} value - the new value
   * @param {string} label - the label of the field being updated
   * @returns {object} - the updated client settings object
   */
  const updated = Object.assign(data, data);

  if (arrayFields.includes(attribute)) {
    // text fields return a string, split and trim leading/trailing whitespace
    const valueArray = value.split(',').map((str) => str.trim());
    if (intFields.includes(attribute)) {
      // handle when array of int value is empty otherwise it would save as 0
      if (valueArray.length === 1 && valueArray[0] === '') {
        updated[attribute] = null;
      } else {
        const intArray = valueArray.map((item) => Number(item));
        updated[attribute] = intArray;
      }
    } else if (attribute === 'multi_member_clients' || attribute === 'multi_member_sms_clients' || attribute === 'multi_member_mid_level_clients') {
      // lets copy the current value of the attribute
      const currentValue = updated[attribute]; // this should be an array or null.
      // if the current value is null, we need to create an array with the input value in it.
      if (currentValue === null) {
        updated[attribute] = [value];
      } else {
        // if it's an array (already has clients set),
        // we need to set the value at the correct index
        const clientIndex = label.slice(-1) - 1;
        // if the user is trying to remove the client currenty set at the index
        if (value === '') {
          // remove the client from the array and replace with nothing
          currentValue.splice(clientIndex, 1);
        } else {
          // otherwise, replace the client at the index with the new value
          currentValue.splice(clientIndex, 1, value);
        }
        updated[attribute] = currentValue;
      }
      // if the value is in the resetFieldValues array, and is an array field, set to an empty array
    } else if (valueArray.some((item) => resetFieldValues.includes(item))) {
      updated[attribute] = [];
    } else {
      updated[attribute] = valueArray;
    }
  } else if (intFields.includes(attribute)) {
    if (attribute === 'donor_prop') {
      // if this is an int, then make it a decimal when saving
      updated[attribute] = formatPercentFloat(value);
    } else {
      updated[attribute] = value === '' ? null : Number(value);
    }
  } else if (resetFieldValues.includes(value)) {
    // if the value is in the resetFieldValues array, and isn't an array or int field,
    // set the value to an empty string
    updated[attribute] = null;
  } else {
    updated[attribute] = value;
  }

  return updated;
};

const capitalizeFirstLetter = (val) => {
  /**
   * Capitalizes first letter of string if it is not undefined or null
   * @param {String} val - string we want to capitalize
   * @returns {String} - empty string: if val is undefined/null
   * if not empty: returns string with capitalized first letter
   */
  if (typeof val === 'undefined' || val === null || val.trim().length === 0) {
    return '';
  }
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
};

const getDeliveryTypeShortName = (deliveryType) => {
  /**
   * Get and return value for short name given to delivery type
   * @param {string} deliveryType - submitted delivery type
   * @return {string} delivery short name
   */

  const mapping = {
    acquisition: 'acq',
    lookalike: 'lookalike',
    reactivation: 'reac',
    mid_level_Co_Op: 'midlvl',
    missionsms_data_only: 'msms_data_only',
    missionsms_list_tiering: 'msms_list_tier',
    missionsms_rental: 'msms',
    sms_list_append: 'sms_append',
  };

  // make deliveryType lowercase to match mapping key
  const formattedDeliveryType = deliveryType.toLowerCase();

  return mapping[formattedDeliveryType];
};

const createDeliveryId = (clientId, deliveryType, deliveryCount) => {
  /**
   * Create delivery Id that will be stored in DB
   * Structure following client id + shortened delivery_type + client delivery count
   * @param {string} clientId - client id for submitted delivery
   * @param {string} deliveryType - submitted delivery type
   * @param {string} deliveryCount - count for this delivery
   * @returns {string} deliveryId - created deliveryId in uppercase letters
   */

  const shortDeliveryType = getDeliveryTypeShortName(deliveryType);

  const deliveryId = `${clientId}_${shortDeliveryType}_${deliveryCount}`;

  return deliveryId.toUpperCase();
};

export {
  arrayIdxForMatchingId,
  deepEqualsObj,
  getRowIdxForId,
  getObjFromAr,
  getArFromObj,
  getLocalStorageTablePref,
  setLocalStorageTablePref,
  tableNameTablePrefObj,
  updateTablePreferenceLocalStorage,
  logReactErrBoundaryError,
  diffListOfObjects,
  tableColumnPreferenceLoader,
  formatInt,
  formatPercentFloat,
  clientSettingsUpdater,
  capitalizeFirstLetter,
  createDeliveryId,
  getDeliveryTypeShortName,
};
