/* eslint-disable max-len */
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
import { useParams, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  Stepper, Step, StepLabel, Button, Box, Typography,
} from '@mui/material';
import {
  logReactErrBoundaryError, capitalizeFirstLetter, formatInt, createDeliveryId,
} from '../utils';
import AcquisitionGeneralFields from '../components/deliveriesForm/AcquisitionGeneralFields';
import AcquisitionOptionalFields from '../components/deliveriesForm/AcquisitionOptionalFields';
import AcquisitionPMFields from '../components/deliveriesForm/AcquisitionPMFields';
import AcquisitionReviewForm from '../components/deliveriesForm/AcquisitionReviewForm';
import APIWrapper from '../utils/graphqlwrapper';
import logger from '../utils/logger';
import { alphabetizeCols } from '../utils/tableHelpers';
import { deliveriesIntFields } from '../utils/deliveriesConstants';
import AppContext from '../context';
import FallbackOnError from '../components/FallbackOnError';
import { REACT_APP_ENVIRON } from '../aws-config';

function AcquisitionProduct({ hasTDCAdminPermission }) {
  const { setShouldLogOut, user } = useContext(AppContext);
  const param = useParams();
  const location = useLocation();
  const deliveryType = (location.state && location.state.deliveryType) || 'Acquisition';
  const { client } = param;
  const [activeStep, setActiveStep] = useState(0);
  const [clientNames, setClientNames] = useState([]);
  const [confirmedClient, setConfirmedClient] = useState(true);
  const [validCohortSplit, setValidCohortSplit] = useState(true);
  const [alertError, setAlertError] = useState({});
  const [clientsList, setClientsList] = useState([]);
  const [cohortCount, setCohortCount] = useState([1]);
  const [openCohortSplit, setOpenCohortSplit] = useState(false);
  const [validExclude, setValidExclude] = useState(true);
  const [validZipcodes, setValidZipcodes] = useState(true);
  const [formData, setFormData] = useState({
    client_id: client || '',
    delivery_date: '',
    number_of_names: '',
    number_of_free_names: 0,
    reason: '',
    approved_by: '',
    cost_per_name: '',
    cost_per_order: '',
    isCohortSplit: false,
    cohortCount: [1],
    days_ago_recent: 30,
    delivery_type: deliveryType,
    file_uploads: {},
    allow_nonrecent_data: 'False',
    invoice_number: '',
    payment_received: '',
  });
  const clientNamesList = [];
  let steps = [];

  const formatCommaDeliniatedField = (fieldname, value) => {
    /**
     * Will create an array of strings for comma deliniated fields
     * If fieldname is for zipcode, then check that zipcodes are 5 digits
     * @param {string} fieldname - key name
     * @param {string} value - value associated with key
     */
    let testRegex;
    const regex = /^(?:\d{5}(?! *, *,)[ ,]+)*\b\d{5}$/;
    const array = value.split(',').map((item) => item.trim());

    if (fieldname.includes('zipcodes')) {
      array.forEach((arrItem) => {
        // check if zipcode is 5 digits long
        testRegex = regex.test(arrItem);
        if (!testRegex && arrItem !== '') {
          setValidZipcodes(false);
        } else {
          setValidZipcodes(true);
        }
      });
    }
    setFormData({ ...formData, [fieldname]: array });
  };

  const updateClientDeliveryCount = async (clientCode) => {
    /**
     * Used to update client's delivery_count after creating a new delivery
     * @param {string} clientCode - client_id to update info for
     */

    const result = await APIWrapper.queryApi(
      { mutation: 'updateClient' },
      setShouldLogOut,
      { input: { client_id: clientCode, delivery_count: formData.current_delivery_count } },
    )
      .then((resp) => resp)
      .catch((err) => logger.error(`Error updating client delivery count: ${err}`));
    return result;
  };

  const handleChange = (e) => {
    // handle changes to delivery form
    let updatedValue;
    if (e.autocomplete) {
      // handle chip selection for mui autocomplete component
      const key = Object.keys(e.autocomplete);
      const value = Object.values(e.autocomplete);
      setFormData({
        ...formData,
        [key]: value[0],
      });
    } else if (e.target.name.endsWith('_zipcodes') || e.target.name.endsWith('_custom_domains')) {
      formatCommaDeliniatedField(e.target.name, e.target.value);
    } else if (deliveriesIntFields.includes(e.target.name)) {
      updatedValue = formatInt(e.target.value);
      setFormData({
        ...formData,
        [e.target.name]: updatedValue,
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: !Number.isNaN(Number(e.target.value)) ? e.target.value : e.target.value.toLowerCase(),
      });
    }
  };

  const calculateNToSelect = (numOfNames, count) => {
    /**
     * If this is a cohort split,
     * calculates n_to_select that will be stored for each cohort in the delivery_config
     * based on number_of_names and each cohort percentage split
     * @params {string} numOfNames for cohort
     * @params  {int} count - cohort number
     * @returns {string} json string of delivery config object
     */
    let formattedValue;

    const splitDecimal = Number(formData[`cohort_${count}_split`] / 100);
    const calcValue = Number(numOfNames * splitDecimal);

    // check if number is odd
    if (calcValue % 2 !== 0 && count === 1) {
      // round down
      formattedValue = Math.floor(calcValue);
    } else if (calcValue % 2 !== 0 && count === 2) {
      // round up
      formattedValue = Math.ceil(calcValue);
    } else {
      formattedValue = calcValue;
    }

    return formattedValue;
  };

  const buildDeliveryConfig = () => {
    /**
     * Create delivery config object that will be stored in DB
     * This includes fields user filled out from the delivery form
     * This will also create the deliverySplitsList with cohort specific information
     * @returns {string} json string of delivery config object
     */
    let nToSelectValue;
    let cohortSplitVal;

    const deliveryConfigObject = {
      // parse allow_nonrecent_data from string to boolean
      allow_nonrecent_data: formData.allow_nonrecent_data.toLowerCase() === 'true',
      client: formData.client_id,
      control_size: formData.number_of_control_names,
      days_ago_active: formData.days_ago_active,
      days_ago_recent: formData.days_ago_recent,
      delivery_type: formData.delivery_type.toLowerCase(),
      desired_donor_floor: formData.desired_donor_floor ? Number(formData.desired_donor_floor) : null,
      donor_floor_type: formData.donor_floor_type,
      recent_delivery_exclude_days: formData.exclude_delivery_days ? Number(formData.exclude_delivery_days) : null,
      recent_delivery_exclude_types: formData.exclude_delivery_type,
      n_to_select: formData.n_to_select,
      email_exclusions_file_path: [],
      custom_universe_file_path: [],
      invoice_number: formData.invoice_number,
      payment_received: formData.payment_received,
    };

    // s3://dromo-user-upload-missionportal-${REACT_APP_ENVIRON}/${metadata.id}.json
    const deliverySplitsList = [];
    const variables = {
      includeStates: undefined,
      excludeStates: undefined,
      includeDomainGroups: undefined,
      excludeDomainGroups: undefined,
      includeCustomDomainGroups: undefined,
      excludeCustomDomainGroups: undefined,
      includeZipcodes: undefined,
      excludeZipcodes: undefined,
    };

    // loop and check if exclude type fields then pushing values into array
    // for either the include or exclude array
    formData.cohortCount.forEach((count) => {
      const cohortExclusionFields = [{
        field: `cohort_${count}_geographic_exclusion_type`, include: 'includeStates', exclude: 'excludeStates', formValue: `cohort_${count}_states`,
      }, {
        field: `cohort_${count}_domain_exclusion_type`, include: 'includeDomainGroups', exclude: 'excludeDomainGroups', formValue: `cohort_${count}_domains`,
      },
      {
        field: `cohort_${count}_custom_domain_exclusion_type`, include: 'includeCustomDomainGroups', exclude: 'excludeCustomDomainGroups', formValue: `cohort_${count}_custom_domains`,
      },
      {
        field: `cohort_${count}_zipcodes_exclusion_type`, include: 'includeZipcodes', exclude: 'excludeZipcodes', formValue: `cohort_${count}_zipcodes`,
      },
      ];

      // get approriate values for include/exclude based on user's input
      cohortExclusionFields.forEach((att) => {
        const { field } = att;
        if (formData[field] === 'exclude') {
          variables[att.include] = [];
          variables[att.exclude] = (
            formData[att.formValue] ? formData[att.formValue].filter((n) => n !== '')
              : []);
        } else if (formData[field] === 'include') {
          variables[att.include] = (
            formData[att.formValue] ? formData[att.formValue].filter((n) => n !== '')
              : []);
          variables[att.exclude] = [];
        } else {
          variables[att.include] = [];
          variables[att.exclude] = [];
        }
      });

      if (formData.isCohortSplit) {
        nToSelectValue = calculateNToSelect(Number(formData.number_of_names), count);
        cohortSplitVal = Number(formData[`cohort_${count}_split`]);
      } else {
        nToSelectValue = (Number(formData.number_of_names));
        cohortSplitVal = null;
      }

      // build delivery_splits_list
      deliverySplitsList.push({
        n_to_select: nToSelectValue,
        cohort_split: cohortSplitVal,
        include_states: variables.includeStates,
        exclude_states: variables.excludeStates,
        preset_wanted_domain_groups: variables.includeDomainGroups,
        preset_unwanted_domain_groups: variables.excludeDomainGroups,
        include_zips: variables.includeZipcodes,
        exclude_zips: variables.excludeZipcodes,
        custom_wanted_domains: variables.includeCustomDomainGroups,
        custom_unwanted_domains: variables.excludeCustomDomainGroups,
      });
    });

    // add array to deliveryConfigObject
    deliveryConfigObject.delivery_splits_list = deliverySplitsList;

    // add saved file paths to deliveryConfigObject
    const fileTypesUploaded = Object.keys(formData.file_uploads);
    fileTypesUploaded.forEach((key) => {
      const fileNames = Object.keys(formData.file_uploads[key]);
      fileNames.forEach((fileName) => {
        if (key === 'acquistion_exclusions') {
          deliveryConfigObject.email_exclusions_file_path.push(`s3://dromo-user-upload-missionportal-${REACT_APP_ENVIRON}/${formData.file_uploads[key][fileName]}.json`);
        } else if (key === 'custom_universe') {
          deliveryConfigObject.custom_universe_file_path.push(`s3://dromo-user-upload-missionportal-${REACT_APP_ENVIRON}/${formData.file_uploads[key][fileName]}.json`);
        }
      });
    });

    return JSON.stringify(deliveryConfigObject);
  };

  const formatDeliveryInput = (status) => {
    /**
     * Create delivery row input that will be stored in DB
     * @returns {object} input - object with data to save to DB
     */

    // since these fields aren't required when saving a draft, set to default value of 0
    const draftRequiredFields = ['cost_per_name', 'cost_per_order'];

    if (status === 'Draft') {
      draftRequiredFields.forEach((val) => {
        if (formData[val] === '') {
          formData[val] = 0;
        }
      });
    }

    const uuid = uuidv4();
    const input = {
      id: uuid,
      client_id: formData.client_id,
      cost_per_name: formData.cost_per_name,
      cost_per_order: formData.cost_per_order,
      number_of_free_names: formData.number_of_free_names,
      reason: formData.reason,
      approved_by: formData.approved_by,
      invoice_number: formData.invoice_number,
      payment_received: formData.payment_received,
      delivery_config: buildDeliveryConfig(),
      delivery_date: formData.delivery_date,
      delivery_id: createDeliveryId(formData.client_id, formData.delivery_type, formData.current_delivery_count),
      delivery_type: formData.delivery_type,
      status,
      pm_comments: formData.pm_comments,
      file_uploads: JSON.stringify(formData.file_uploads),
    };
    return input;
  };

  const handleSubmit = (status) => {
    /**
     * Handle submission and send data to dynamo through appsync
     * This will create an entry in the deliveries table
     * This will also update the client's delivery_count field in the client's table
     * @param {string} status - draft or submitted
     */
    const deliveryInput = formatDeliveryInput(status);
    APIWrapper.queryApi(
      { mutation: 'createDeliveries' },
      setShouldLogOut,
      {
        input: deliveryInput,
      },
    )
      .then((resp) => {
        updateClientDeliveryCount(formData.client_id);
        return resp;
      })
      .catch((e) => logger.error(`Error submitting form and creating delivery: ${e}`));
  };

  const handleChangePage = (page) => {
    /**
     * Handle when user clicks on next or back buttons
     * Show alert so users need to fix issues before continuing to next page
     * @param {string} page - Next or Back
     */

    // check that required fields are filled in by user
    const requiredFields = ['client_id', 'number_of_names', 'cost_per_name', 'delivery_date'];
    let missingField;

    requiredFields.forEach((att) => {
      if (!formData[att]) {
        missingField = true;
      }
    });

    if (missingField) {
      setAlertError({
        severity: 'error',
        message: 'Please make sure to fill out all required fields',
      });
    } else if (!confirmedClient) {
      // confirm client on front end user's select field
      // if user doesnt navigate from client's page
      setAlertError({
        severity: 'error',
        message: 'Please confirm client selection',
      });
    } else if (formData.cost_per_order < 7500 || formData.cost_per_order <= 0) {
      setAlertError({
        severity: 'error',
        message: formData.cost_per_order < 7500 ? 'Please make sure number of names is greater than or equal to 7500' : 'Please make sure cost per name is greater than 0',
      });
    } else if (!validCohortSplit) {
      // if user selects multiple cohorts but the split is not equal to 100
      setAlertError({
        severity: 'error',
        message: 'Please make sure cohort percentage split is equal to 100',
      });
    } else if (!validExclude) {
      // user selected values for domains, zipcodes, or states
      // without specifying exclusion type
      setAlertError({
        severity: 'error',
        message: 'Please select exclusion type and value for selected restrictions',
      });
    } else if (!validZipcodes) {
      setAlertError({
        severity: 'error',
        message: 'Please make sure zipcodes listed are 5 digits long',
      });
    } else if (page === 'Next') {
      setAlertError({});
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (page === 'Back') {
      setAlertError({});
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const getClients = async () => {
    /**
     * When user is not scoped to client, fetch client list for user to choose from
     * Format client list information into arrays for dropdown
     * This will be used to get chosen client's client type and delivery count
     */
    const result = await APIWrapper.queryApi(
      { query: 'listClients' },
      false,
      {
        limit: 1000,
      },
    )
      .then((resp) => {
        const alphabetizedCols = alphabetizeCols(resp.data[0], 'client_full_name');
        const clientInfoList = [];
        let clientName;
        alphabetizedCols.forEach((cl) => {
          clientName = (cl.client_full_name).toLowerCase();
          // get client name, id, delivery count, and type
          clientInfoList.push({
            client: capitalizeFirstLetter(clientName),
            client_id: cl.client_id,
            client_type: cl.client_type,
            delivery_count: cl.delivery_count ? cl.delivery_count : 0,
          });
          // for client dropdown
          clientNamesList.push(capitalizeFirstLetter(clientName));
        });
        setClientNames(clientNamesList);
        setClientsList(clientInfoList);
      })
      .catch((err) => logger.error(`Error listing clients for dropdown: ${err}`));
    return result;
  };

  const getClientInfo = async (clientCode) => {
    /**
     * Used to get client's client type (nonprofit or political)
     * Also set default PM values based on type
     * @param {string} clientCode - client_id to get info for
     */
    let clientType;
    const result = await APIWrapper.queryApi(
      { query: 'getClient' },
      false,
      {
        client_id: clientCode,
      },
    )
      .then((resp) => {
        clientType = resp.data[0][0].client_type;
        setFormData({
          ...formData,
          client_id: clientCode,
          clientType: resp.data[0][0].client_type,
          current_delivery_count: resp.data[0][0].delivery_count ? (resp.data[0][0].delivery_count + 1) : 1,
          // set default PM values
          days_ago_active: clientType === 'political' ? 30 : 365,
          donor_floor_type: clientType === 'nonprofit' ? 'Nonprofit' : 'All',
          desired_donor_floor: clientType === 'nonprofit' ? 0.8 : 0.3,
        });
      })
      .catch((err) => logger.error(`Error getting client information by client id: ${err}`));
    return result;
  };

  // Stepper for form
  steps = [
    {
      label: 'General information',
      pageComponent: <AcquisitionGeneralFields
        formData={formData}
        setFormData={setFormData}
        clientNamesList={clientNames}
        clientsList={clientsList}
        handleChange={handleChange}
        confirmedClient={confirmedClient}
        setConfirmedClient={setConfirmedClient}
        setAlertError={setAlertError}
        alertError={alertError}
        hasTDCAdminPermission={hasTDCAdminPermission}
      />,
    },
    {
      label: 'Optional fields',
      pageComponent: <AcquisitionOptionalFields
        user={user}
        formData={formData}
        setFormData={setFormData}
        handleChange={handleChange}
        validCohortSplit={validCohortSplit}
        setValidCohortSplit={setValidCohortSplit}
        alertError={alertError}
        cohortCount={cohortCount}
        setCohortCount={setCohortCount}
        openCohortSplit={openCohortSplit}
        setOpenCohortSplit={setOpenCohortSplit}
        setValidExclude={setValidExclude}
        validZipcodes={validZipcodes}
      />,
    },
    {
      label: 'Review and submit',
      pageComponent: <AcquisitionReviewForm
        formData={formData}
        hasTDCAdminPermission={hasTDCAdminPermission}
      />,
    },
  ];

  if (hasTDCAdminPermission) {
    const pmObject = {
      label: 'PM fields',
      pageComponent: <AcquisitionPMFields
        formData={formData}
        handleChange={handleChange}
      />,
    };
    steps.splice(2, 0, pmObject);
  }

  useEffect(() => {
    if (client) {
      document.title = `MissionPortal | ${client} Acquisition`;
      getClientInfo(client);
    } else {
      document.title = 'MissionPortal | Acquisition';
      setConfirmedClient(false);
      getClients();
    }
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={FallbackOnError}
      onError={logReactErrBoundaryError}
      onReset={(details) => {
        logger.info('details', details);
        // Reset the state of your app so the error doesn't happen again
      }}
    >
      <header className="deliveryform-header">
        {formData.client_id
          ? (
            <h2>
              {`${formData.client_id} - Acquisition`}
            </h2>
          )
          : <h2>{deliveryType.replace(/_/g, ' ')}</h2>}
      </header>
      <Box sx={{ width: '100%' }}>
        <Stepper className="deliveries-card-stepper" activeStep={activeStep}>
          {steps.map((step) => (
            <Step key={step.label}>
              <StepLabel>
                {step.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length ? (
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - your submission will be reviewed
          </Typography>
        ) : (
          <>
            <Typography sx={{ mt: 2, mb: 1 }}>
              {steps[activeStep].pageComponent}
            </Typography>
            <Box sx={{
              display: 'flex', flexDirection: 'row', pt: 2, width: '80%', margin: 'auto',
            }}
            >
              <Button
                variant="contained"
                color="inherit"
                disabled={activeStep === 0}
                onClick={() => handleChangePage('Back')}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              {/* Save as draft */}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                {formData.client_id && formData.delivery_date && (
                  <Button sx={{ marginRight: '10px' }} variant="outlined" onClick={() => handleSubmit('Draft')}>
                    Save as Draft
                  </Button>
                )}
                {/* Next button or submit button */}
                {
                  activeStep === steps.length - 1 ? (
                    <Button variant="contained" onClick={() => handleSubmit('Submitted')}>
                      Submit
                    </Button>
                  ) : (
                    <Button variant="contained" onClick={() => handleChangePage('Next')}>
                      Next
                    </Button>
                  )
                }
              </Box>
            </Box>
          </>
        )}
      </Box>
    </ErrorBoundary>
  );
}

export default AcquisitionProduct;

AcquisitionProduct.propTypes = {
  hasTDCAdminPermission: PropTypes.bool,
};
