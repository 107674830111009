import React from 'react';
import PropTypes from 'prop-types';
import {
  TextField, InputLabel, Tooltip, IconButton,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

function DeliveriesTextField(props) {
  const {
    value,
    isValid,
    isDisabled,
    handleChange,
    name,
    label,
    tooltip,
    customHelperText,
    type,
    placeholder,
    isMultiline,
    customWidth,
  } = props;

  function checkValidity(input) {
    /**
     * This function checks the validity of the input and changes the helper text
     * and validity state accordingly
     * @param {String} input - The input value
     * @return {String} - The helper text
     */
    if (type === 'integer') {
      const isANumber = !Number.isNaN(Number(input));
      if (!isANumber) {
        return 'Must be an integer';
      }
      if (isANumber) {
        if (name === 'cost_per_order' && input < 7500) {
          return 'Value must be greater than or equal to 7.5k';
        }
        if (input <= 0) {
          return 'Value must be greater than 0';
        }
      }
    }
    return '';
  }

  const helperText = (
    <>
      {customHelperText && <p data-testid={`${name}-help`}>{customHelperText}</p>}
      {value !== '' && checkValidity(value) && <p data-testid={`${name}-err`} style={{ color: 'red' }}>{checkValidity(value)}</p>}
    </>
  );

  return (
    <>
      <InputLabel className={isDisabled ? 'disabledDeliveryFormLabel' : 'deliveryFormLabel'} htmlFor={name}>
        {label}
        {tooltip && (
          <Tooltip title={tooltip} placement="right-start">
            <IconButton size="small">
              <InfoIcon />
            </IconButton>
          </Tooltip>
        )}
      </InputLabel>
      <TextField
        id={name}
        sx={{
          width: customWidth || '500px',
          margin: 'auto',
          '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: '#585656fa',
          },
        }}
        onChange={handleChange}
        value={value}
        disabled={isDisabled}
        name={name}
        error={isValid}
        type="text"
        helperText={helperText}
        placeholder={placeholder}
        multiline={isMultiline}
      />
    </>
  );
}

export default DeliveriesTextField;

DeliveriesTextField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  label: PropTypes.string,
  tooltip: PropTypes.string,
  handleChange: PropTypes.func,
  customHelperText: PropTypes.string,
  customWidth: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  isMultiline: PropTypes.bool,
  isValid: PropTypes.bool,
};
