/* eslint-disable max-len */
/* eslint-disable no-plusplus */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Card, Button, Box, Tooltip, IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import InfoIcon from '@mui/icons-material/Info';
import { logReactErrBoundaryError } from '../../utils';
import { acquisitionDeliveriesListStructure } from '../../utils/deliveriesConstants';
import DeliveriesDropDown from '../deliveriesFields/DeliveriesDropdown';
import DeliveriesTextField from '../deliveriesFields/DeliveriesTextField';
import Alert from '../../utils/Alert';
import FallbackOnError from '../FallbackOnError';
import logger from '../../utils/logger';
import DromoUpload from '../DromoUpload';

function AcquisitionOptionalFields({
  user,
  formData,
  setFormData,
  handleChange,
  validCohortSplit,
  setValidCohortSplit,
  alertError,
  cohortCount,
  setCohortCount,
  openCohortSplit,
  setOpenCohortSplit,
  setValidExclude,
  validZipcodes,
}) {
  const [customUniverseFiles, setCustomUniverseFiles] = useState(formData.file_uploads.custom_universe ? Object.keys(formData.file_uploads.custom_universe) : []);
  const [acquisitionExclusionsFiles, setAcquisitionExclusionsFiles] = useState(formData.file_uploads.acquistion_exclusions ? Object.keys(formData.file_uploads.acquistion_exclusions) : []);

  const checkSum = () => {
    /**
     * Will calculate cohort split and check that sum is not < or > 100
     * Will set validCohortSplit as false if value != 100
     * This will set an alert on the front end for the user
     * If it is valid, then no error will show
     * @returns {boolean}
    */
    if (formData.cohort_1_split && formData.cohort_2_split) {
      const sum = Number(formData.cohort_1_split) + Number(formData.cohort_2_split);
      if (sum > 100 || sum < 100) {
        setValidCohortSplit(false);
        return true;
      }
      setValidCohortSplit(true);
    }
    return false;
  };

  /**
   * Will check cohort specific fields and verify that an exclusion type is specified
   * Values being checked are: states, zipcodes, domains, or custom domains
   * If values are chosen without specifying an exclusion type, return false
   * Set state for validExclude to show alert to user to specify an exclusion type
   * @returns {boolean}
   */
  const checkExclusionType = () => cohortCount.some((cohort) => {
    if (
      (!formData[`cohort_${cohort}_geographic_exclusion_type`] && formData[`cohort_${cohort}_states`] && formData[`cohort_${cohort}_states`].length > 0)
      || (!formData[`cohort_${cohort}_zipcodes_exclusion_type`] && formData[`cohort_${cohort}_zipcodes`] && formData[`cohort_${cohort}_zipcodes`].length > 0 && !formData[`cohort_${cohort}_zipcodes`].every((item) => item === ''))
      || (!formData[`cohort_${cohort}_domain_exclusion_type`] && formData[`cohort_${cohort}_domains`] && formData[`cohort_${cohort}_domains`].length > 0)
      || (!formData[`cohort_${cohort}_custom_domain_exclusion_type`] && formData[`cohort_${cohort}_custom_domains`] && formData[`cohort_${cohort}_custom_domains`].length > 0 && !formData[`cohort_${cohort}_custom_domains`].every((item) => item === ''))
    ) {
      setValidExclude(false);
      return true;
    }
    setValidExclude(true);
    return false;
  });

  const validateCustomDomain = (cohort) => {
    const customDomains = formData[`cohort_${cohort}_custom_domains`];
    const isValid = customDomains && customDomains.every((domain) => {
      const parts = domain.split('.');
      return parts.length > 1 && parts.every((part) => part.length > 0);
    });
    setValidExclude(isValid);
    return isValid;
  };

  const handleOpenCohortSplit = () => {
    /**
      * handle opening cohort split fields when user clicks on Add Cohort button
      * this will add to cohortCount array and set isCohortSplit to true
      * the cohortCount array will then be used to:
      * dynamically map through and render components and name them based on cohort value
    */
    setOpenCohortSplit(true);
    // signal that this is a cohort split
    setValidCohortSplit(false);
    setCohortCount([1, 2]);
    setFormData({ ...formData, isCohortSplit: true, cohortCount: [1, 2] });
  };

  const handleCancelCohortSplit = () => {
    /**
      * handle opening cohort split fields when user clicks on Cancel button
      * set cohortCount back to the mininum count and isCohortSplit to false
    */
    // reset to min num of cohort
    setOpenCohortSplit(false);
    // user canceled split. signal that this is not a cohort split.
    setValidCohortSplit(true);
    setCohortCount([1]);
    setFormData({ ...formData, isCohortSplit: false, cohortCount: [1] });
  };

  const handleUpload = (metadata) => {
    /**
     * This will handle saving the metadata to the form data object
     * @param {object} metadata - the metadata object returned from the uploader
     */
    const fileUploads = formData.file_uploads;
    // if we don't have a key for this importIdentifier, we need to create a new key value pair
    if (!fileUploads[metadata.importIdentifier]) {
      fileUploads[metadata.importIdentifier] = { [metadata.filename]: metadata.id };
    }
    // if we already have a key for this importIdentifier, we need to add a new key value pair
    if (fileUploads[metadata.importIdentifier]) {
      fileUploads[metadata.importIdentifier][metadata.filename] = metadata.id;
    }
    setFormData({ ...formData, file_uploads: fileUploads });
    if (metadata.importIdentifier === 'acquistion_exclusions') setAcquisitionExclusionsFiles([...acquisitionExclusionsFiles, metadata.filename]);
    if (metadata.importIdentifier === 'custom_universe') setCustomUniverseFiles([...customUniverseFiles, metadata.filename]);
  };

  const handleRemoveFile = (type, file) => {
    /**
     * handle removing file from file_uploads object and set state for fileNames
     * @param {string} type - the type of file being removed
     * @param {string} file - the file name being removed
     */
    const fileUploads = formData.file_uploads[type];
    delete fileUploads[file];
    // if that is the last file, remove the key also
    if (Object.keys(fileUploads).length === 0) {
      const formDataUploadsCopy = { ...formData.file_uploads };
      delete formDataUploadsCopy[type];
      setFormData({ ...formData, file_uploads: formDataUploadsCopy });
    } else {
      setFormData({ ...formData, file_uploads: { ...formData.file_uploads, [type]: fileUploads } });
    }
    if (type === 'acquistion_exclusions') {
      const newFiles = acquisitionExclusionsFiles.filter((f) => f !== file);
      setAcquisitionExclusionsFiles(newFiles);
    }
    if (type === 'custom_universe') {
      const newFiles = customUniverseFiles.filter((f) => f !== file);
      setCustomUniverseFiles(newFiles);
    }
  };

  const importFields = [
    {
      label: 'Email',
      key: 'email',
      requireMapping: true,
      type: 'email',
    },
  ];

  return (
    <ErrorBoundary
      FallbackComponent={FallbackOnError}
      onError={logReactErrBoundaryError}
      onReset={(details) => {
        logger.info('Error boundary resetting: ', details);
        // Todo: Reset the state of your app so the error doesn't happen again
      }}
    >
      <Card className="acquisition-form-card">
        <Box sx={{ padding: 2 }}>
          <div id="alert-message">
            {Object.keys(alertError).length > 0
              && (
                <Alert
                  alert={alertError}
                />
              )}
          </div>
          <Box sx={{
            display: 'flex', flexDirection: 'column', margin: 'auto', width: '600px',
          }}
          >
            <h4>Cohort Percentage Split</h4>
            {formData.isCohortSplit && cohortCount.map((cohort) => (
              <DeliveriesTextField
                handleChange={handleChange}
                value={formData[`cohort_${cohort}_split`] || ''}
                name={`cohort_${cohort}_split`}
                type="integer"
                label={`Cohort ${cohort}`}
                isValid={checkSum()}
                customWidth="100%"
              />
            ))}
          </Box>
          <Box sx={{ margin: 'auto', width: '600px' }}>
            {formData.isCohortSplit && !validCohortSplit && <p id="cohort-split-error">The sum of these fields must equal 100</p>}
          </Box>
          <Box sx={{ margin: 'auto', width: '600px' }}>
            {openCohortSplit ? (
              <Button sx={{ width: '100%' }} onClick={handleCancelCohortSplit} variant="contained">
                Cancel
              </Button>
            ) : (
              <Button sx={{ width: '100%' }} onClick={handleOpenCohortSplit} variant="contained">
                Add Cohort
                <AddIcon />
              </Button>
            )}
          </Box>
          <h4 className="delivery-text">
            Geographic Restrictions
            <Tooltip
              title={(
                <>
                  <p>
                    <strong>Exclude:</strong>
                    {' '}
                    names of the selected locations will not be in this delivery
                  </p>
                  <p>
                    <strong>Include:</strong>
                    {' '}
                    only names of the selected locations will be in this delivery
                  </p>
                </>
              )}
              placement="right-start"
            >
              <IconButton size="small">
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </h4>
          {cohortCount.map((cohort) => (
            <>
              <Box sx={{
                margin: 'auto', width: '600px',
              }}
              >
                {openCohortSplit && (
                  <h4>
                    {`Cohort ${cohort}`}
                  </h4>
                )}
                <p>States</p>
              </Box>
              <Box sx={{
                display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: 'auto', width: '600px',
              }}
              >
                <DeliveriesDropDown
                  value={formData[`cohort_${cohort}_geographic_exclusion_type`] || ''}
                  handleChange={handleChange}
                  textLabel="Select Exclusion Type"
                  menuList={['Include', 'Exclude']}
                  name={`cohort_${cohort}_geographic_exclusion_type`}
                  customWidth="35%"
                />
                <DeliveriesDropDown
                  value={formData[`cohort_${cohort}_states`] || []}
                  handleChange={handleChange}
                  menuList={acquisitionDeliveriesListStructure.statesList}
                  isAutocomplete
                  placeholder="Select States"
                  name={`cohort_${cohort}_states`}
                  isValid={formData[`cohort_${cohort}_states`] && checkExclusionType()}
                  customWidth="60%"
                />
              </Box>
              <p className="delivery-text">Zipcodes</p>
              <Box sx={{
                display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: 'auto', width: '600px',
              }}
              >
                <DeliveriesDropDown
                  value={formData[`cohort_${cohort}_zipcodes_exclusion_type`] || ''}
                  handleChange={handleChange}
                  textLabel="Select Exclusion Type"
                  menuList={['Include', 'Exclude']}
                  name={`cohort_${cohort}_zipcodes_exclusion_type`}
                  customWidth="35%"
                />
                <DeliveriesTextField
                  value={formData[`cohort_${cohort}_zipcodes`]}
                  handleChange={handleChange}
                  name={`cohort_${cohort}_zipcodes`}
                  isMultiline
                  customWidth="60%"
                  isValid={formData[`cohort_${cohort}_zipcodes`] && checkExclusionType()}
                />
              </Box>
              {validZipcodes ? <p className="delivery-text helper">Must be comma delineated</p> : <p className="delivery-text helper">Zipcodes must be 5 digits long</p>}
            </>
          ))}
          <h4 className="delivery-text">
            Domain Restrictions
            <Tooltip
              title={(
                <>
                  <p>
                    <strong>Exclude:</strong>
                    {' '}
                    names with the selected domains will not be in this delivery
                  </p>
                  <p>
                    <strong>Include:</strong>
                    {' '}
                    only names with the selected domains will be in this delivery
                  </p>
                </>
              )}
              placement="right-start"
            >
              <IconButton size="small">
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </h4>
          {cohortCount.map((cohort) => (
            <>
              <Box sx={{
                margin: 'auto', width: '600px',
              }}
              >
                {openCohortSplit && (
                  <h4>
                    {`Cohort ${cohort}`}
                  </h4>
                )}
                <p>Domains</p>
              </Box>
              <Box sx={{
                display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: 'auto', width: '600px',
              }}
              >
                <DeliveriesDropDown
                  value={formData[`cohort_${cohort}_domain_exclusion_type`] || ''}
                  handleChange={handleChange}
                  textLabel="Select Exclusion Type"
                  menuList={['Include', 'Exclude']}
                  name={`cohort_${cohort}_domain_exclusion_type`}
                  customWidth="35%"
                />
                <DeliveriesDropDown
                  value={formData[`cohort_${cohort}_domains`] || []}
                  handleChange={handleChange}
                  menuList={acquisitionDeliveriesListStructure.domainGroupsList}
                  isAutocomplete
                  autocompleteLowercase
                  placeholder="Select Domain Restrictions"
                  name={`cohort_${cohort}_domains`}
                  isValid={formData[`cohort_${cohort}_domains`] && checkExclusionType()}
                  customWidth="60%"
                />
              </Box>
              <p className="delivery-text">Custom Domains</p>
              <Box sx={{
                display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: 'auto', width: '600px',
              }}
              >
                <DeliveriesDropDown
                  value={formData[`cohort_${cohort}_custom_domain_exclusion_type`] || ''}
                  handleChange={handleChange}
                  textLabel="Select Exclusion Type"
                  menuList={['Include', 'Exclude']}
                  name={`cohort_${cohort}_custom_domain_exclusion_type`}
                  customWidth="35%"
                />
                <DeliveriesTextField
                  value={formData[`cohort_${cohort}_custom_domains`]}
                  handleChange={handleChange}
                  name={`cohort_${cohort}_custom_domains`}
                  isMultiline
                  customWidth="60%"
                  isValid={formData[`cohort_${cohort}_custom_domains`] && (checkExclusionType() || !validateCustomDomain(cohort))}
                />
              </Box>
              <p className="delivery-text helper">Must be comma delineated</p>
            </>
          ))}
          <h4 className="delivery-text">File Upload</h4>
          <Box sx={{
            display: 'flex', justifyContent: 'space-between', width: 600, margin: 'auto',
          }}
          >
            <div>
              <DromoUpload
                importFields={importFields}
                importIdentifier="acquistion_exclusions"
                allowCustomFields={false}
                userObject={{ id: user.email }}
                bulkRowHooks={[]}
                buttonText="Upload Exclusions File"
                handleUpload={handleUpload}
              />
              {acquisitionExclusionsFiles.length > 0 && (<h4>Exclusion files:</h4>)}
              {acquisitionExclusionsFiles.length > 0 && (
                acquisitionExclusionsFiles.map((file) => (
                  <div key={file}>
                    <Tooltip title="Remove file" placement="left">
                      <IconButton aria-label="delete" color="error" onClick={() => handleRemoveFile('acquistion_exclusions', file)}>
                        <HighlightOffOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    {file}
                  </div>
                ))
              )}
            </div>
            <div>
              <DromoUpload
                importFields={importFields}
                importIdentifier="custom_universe"
                allowCustomFields={false}
                userObject={{ id: user.email }}
                bulkRowHooks={[]}
                buttonText={(
                  <Tooltip title="If used, the delivery will be composed of names from a file uploaded here instead of names from our Data Lake. No names will be removed for delivery other than ones from an exclusion file or with geographic/domain restrictions so be sure all names are eligible for delivery. Sometimes used for acquisitions from a gifted list." placement="right-start">
                    Upload Custom Inventory
                  </Tooltip>
                )}
                handleUpload={handleUpload}
              />
              {customUniverseFiles.length > 0 && (<h4>Custom Inventory files:</h4>)}
              {customUniverseFiles.length > 0 && (
                customUniverseFiles.map((file) => (
                  <div key={file}>
                    <Tooltip title="Remove file" placement="left">
                      <IconButton aria-label="delete" color="error" onClick={() => handleRemoveFile('custom_universe', file)}>
                        <HighlightOffOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    {file}
                  </div>
                ))
              )}
            </div>
          </Box>
        </Box>
      </Card>
    </ErrorBoundary>
  );
}

export default AcquisitionOptionalFields;

AcquisitionOptionalFields.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object]).isRequired,
  formData: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setFormData: PropTypes.func.isRequired,
  validCohortSplit: PropTypes.bool.isRequired,
  setValidCohortSplit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  cohortCount: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setCohortCount: PropTypes.func.isRequired,
  openCohortSplit: PropTypes.bool.isRequired,
  setOpenCohortSplit: PropTypes.func.isRequired,
  alertError: PropTypes.oneOfType([PropTypes.object]),
  setValidExclude: PropTypes.func.isRequired,
  validZipcodes: PropTypes.bool.isRequired,
};
