/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import React, {
  useEffect, useState, useCallback, useContext,
} from 'react';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import { GridActionsCellItem } from '@mui/x-data-grid-premium';

import logger from '../../utils/logger';
import {
  getRowIdxForId, getLocalStorageTablePref, updateTablePreferenceLocalStorage, diffListOfObjects,
} from '../../utils/index';
import ClientTextField from '../clientFields/ClientTextField';
import ClientDropDown from '../clientFields/ClientDropDown';
import APIWrapper from '../../utils/graphqlwrapper';
import { sourceColumns, channelCategoryEnum, smsChannelEnum } from '../../utils/schemaInfo';

import AppContext from '../../context';

import { createSourceRows } from '../../utils/tableHelpers';
import AdvancedSearchV2 from '../AdvancedSearchV2';
import AdvancedSearchChip from '../AdvancedSearchChip';
import ServerTable from '../ServerTable';
import { CustomDatePicker } from '../DateAndTimePickers';

// eslint-disable-next-line camelcase
const channel_category = ['Select one', ...channelCategoryEnum];
const structure = {
  // eslint-disable-next-line camelcase
  channel_category,
};
const smsChannel = ['Select one', ...smsChannelEnum];
const smsChannelStructure = { channel: smsChannel };

const initColumnVisibilityModel = { from_goop: false };

export default function Sources() {
  const { client } = useParams();
  const { authenticated, setShouldLogOut } = useContext(AppContext);
  const [snackMessage, setSnackMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackWithConfirm, setSnackWithConfirm] = useState(false);
  // used to identify when the source value is changing and we need to make sure the id matches the source
  const [existingSourceUpdateId, setExistingSourceUpdateId] = useState(false);
  const [previousUpdatedId, setPreviousUpdatedId] = useState();
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(initColumnVisibilityModel);
  // used by snackbar action handler to delete source/update source on codes on user confirm
  // {id: str, source: str}
  const [deleteSourceData, setDeleteSourceData] = useState({});
  // used to maintain correct source value on both Sources and Codes tables
  // (on existing source edit); {oldValue: str, newValue: str}
  const [updatedSource, setUpdatedSource] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  // if false, dialog in edit state, if true in create state; used to diff which mutation to call
  const [isDialogInCreateState, setisDialogInCreateState] = useState(false);
  // tracks data in create/edit modal
  const [editRowData, setEditRowData] = useState({});
  const [newSourceRowCounter, setNewSourceRowCounter] = useState(1);
  const [sourcesPageState, setSourcesPageState] = useState({
    page: 0, // mui pages are 0 indexed
    pageSize: 50,
    data: [],
    total: 0,
  });

  let thirtyDaysAgo = new Date(new Date().setDate(new Date().getDate() - 30)); // -30 days from now
  thirtyDaysAgo = new Date(thirtyDaysAgo.setHours(0, 0, 0, 0)); // set to midnight

  // nicely formatted query for our API
  const [advancedSearchQuery, setAdvancedSearchQuery] = useState({
    client_id: {
      eq: client,
    },
    and: {
      createdAt: {
        gte: thirtyDaysAgo.toISOString(),
      },
    },
  });

  // used for chips
  const [userCriteria, setUserCriteria] = useState([]);

  const defaultSearch = [
    {
      col: 'createdAt',
      op: 'gte',
      criteria: thirtyDaysAgo.toISOString(),
      children: [],
      id: 1,
    },
  ];

  // search criteria user has entered in the advanced search
  const [currUserSearch, setCurrUserSearch] = useState(defaultSearch);

  const deleteChip = (parentIndex) => {
    /**
     * This function is used to delete a chip from the advanced search.
     *
     * @param {number} parentIndex - the index of the chip to be deleted
     */
    setCurrUserSearch((prevState) => {
      const newState = [...prevState];
      newState.splice(parentIndex, 1);
      return newState;
    });
  };

  const defaultSort = {
    field: 'createdAt',
    direction: 'desc',
  };

  const [advancedSearchSort, setAdvancedSearchSort] = useState(defaultSort);

  // default/locked criteria the user can't delete - search must be scoped ot current client
  const lockedCriteria = [
    {
      col: 'client_id',
      op: 'eq',
      criteria: client,
    },
  ];

  const getCodesBySource = async (sourceValue) => {
    /**
     * This function is used to get the codes associated with a source for a specific client.
     *
     * @param {string} sourceValue - the source value to be searched for
     */
    const codes = await APIWrapper.queryApi(
      { query: 'codesBySource' },
      false,
      { source: sourceValue, filter: { client_id: { eq: `${client}` } }, limit: 5000 },
    )
      .then((resp) => resp)
      .catch((err) => logger.error(err));
    return codes.data[0];
  };

  const deleteFunc = (id) => {
    /**
     * This function is used to delete a source from the database.
     *
     * @param {string} id - the id of the source to be deleted
     */
    if (authenticated) {
      APIWrapper.queryApi(
        { mutation: 'deleteSources' },
        setShouldLogOut,
        { input: { id } },
      )
        .then((resp) => {
          const results = resp.data[0] || null;
          const sourceValue = results[0].source;
          if (results && !existingSourceUpdateId) {
            setSnackMessage(`${sourceValue} was deleted.`);
            setOpenSnackbar(true);
            // 2 cases
            getCodesBySource(sourceValue)
              .then((codes) => {
                if (!codes.length) {
                  // case1, no matching codes
                  setUpdatedSource({});
                } else {
                  // case2, there are codes attached to source being updated
                  const sourceUpdateData = {
                    oldValue: sourceValue,
                    newValue: 'null',
                  };
                  setUpdatedSource(sourceUpdateData);
                }
              })
              .catch((err) => {
                logger.error(err);
              });
            const latestSources = sourcesPageState.data.filter(
              (r) => r.source !== results[0].source,
            );
            setSourcesPageState({ ...sourcesPageState, data: latestSources });
          }
        });
    }
  };

  async function updateCodesForSourceChangeFunc(updatedCodeIDs, newSourceValue, oldSourceValue) {
    /**
     * This function is used to update the source value for codes in the database. This is handling
     * both the case where the source value is updated to a new string and the case where the source
     * value is cleared to null.
     *
     * @param {array} updatedCodeIDs - the code Id(s) we're updating
     * @param {string} newSourceValue - the new source value
     * @param {string} oldSourceValue - the old source value
     */
    const updatedCodes = [];
    // eslint-disable-next-line no-restricted-syntax
    for await (const code of updatedCodeIDs) {
      APIWrapper.queryApi(
        { mutation: 'updateCodes' },
        setShouldLogOut,
        { input: { id: code, source: newSourceValue } },
      )
        .then((resp) => {
          updatedCodes.push(resp);
          setSnackMessage(`Source was changed from ${oldSourceValue} to ${newSourceValue} for ${updatedCodes.length} codes.`);
          setOpenSnackbar(true);
        })
        .catch((err) => {
          logger.error(err);
          const errMsg = err.errors[0].message || 'Changes to the row could not be saved.';
          setSnackMessage(errMsg);
          setOpenSnackbar(true);
        });
    }
  }

  const handleDeleteClick = (event, row) => {
    /**
     * This function is used to handle the delete click event.
     *
     * @param {object} event - the event object
     * @param {object} row - the row being deleted
     */
    const curRow = row.row;
    const canDeleteRow = !curRow.from_goop;
    let requiresUserConfirm = false;
    // check for codes associated with source
    getCodesBySource(curRow.source)
      .then((codes) => {
        if (codes.length) {
          requiresUserConfirm = true;
        }
        if (!canDeleteRow) {
          setOpenSnackbar(true);
          setSnackMessage('The source was created in Goop and so you need to update it in Goop.');
        } else if (canDeleteRow && requiresUserConfirm) {
          // case where row can be deleted but requires user confirm because affects codes table
          setSnackWithConfirm(true);
          setSnackMessage(`The source you are deleting is associated with ${codes.length} codes currently. If you continue, the source will be deleted and removed from the currently linked codes.`);
          setOpenSnackbar(true);
          setDeleteSourceData({
            id: curRow.id,
            source: curRow.source,
          });
        } else {
          // case where row can be deleted but does not affect codes data
          setSnackWithConfirm(true);
          setSnackMessage('Are you sure you wish to delete this source?');
          setOpenSnackbar(true);
          setDeleteSourceData({
            id: curRow.id,
          });
        }
      })
      .catch((err) => {
        logger.error(err);
      });
  };

  // onclick handler for edit icon in the table actions column
  const handleEditClick = (event, row) => {
    /**
     * This function is used to handle the edit click event, if it is not from Goop then allow the
     * user to edit the row. Otherwise, tell them to edit it in Goop.
     *
     * @param {object} event - the event object
     * @param {object} row - the row being edited
     */
    const curRow = row.row;
    if (!curRow.from_goop) {
      setEditRowData(curRow);
      setIsDialogOpen(true);
    } else {
      setSnackMessage('The source was created in Goop and so you need to update it in Goop.');
      setOpenSnackbar(true);
    }
  };

  const actionCol = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      disableClickEventBubbling: true,
      cellClassName: 'actions',
      disableReorder: true,
      getActions: (row) => [
        <GridActionsCellItem
          icon={<EditIcon color={row.row.from_goop ? 'disabled' : ''} />}
          label="Edit"
          className="textPrimary"
          onClick={(ev) => handleEditClick(ev, row)}
          color="inherit"
          size="medium"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon color={row.row.from_goop ? 'disabled' : ''} />}
          label="Delete"
          onClick={(ev) => handleDeleteClick(ev, row)}
          color="inherit"
          size="medium"
        />,
      ]
      ,
    },
  ];

  const columns = actionCol.concat(sourceColumns);
  const [orderedColumns, setOrderedColumns] = useState(columns);

  function addNewRow() {
    /**
     * This function is used to add a new row to the sources table.
     */
    setisDialogInCreateState(true);
    const newRow = {
      id: '',
      client_id: client,
      source: '',
      channel: '',
      channel_category: '',
      from_goop: false,
      send_date: null,
      send_cost: null,
      send_size: null,
    };
    setEditRowData(newRow);
    setIsDialogOpen(true);
  }

  const validateSourceRow = (newRow) => {
    /**
     * This function is used to validate the row data before sending it to the graphql mutation.
     *
     * @param {object} newRow - the source row data to be validated
     * @returns {boolean} - true if the row is valid, false otherwise
     */
    let snackErrors = '';
    if (!newRow.source) {
      snackErrors += 'Source cannot be empty.';
    }
    const validChannelCategories = structure.channel_category;
    const hasChannelCategoryError = validChannelCategories.indexOf(newRow.channel_category) === -1;
    if (hasChannelCategoryError) {
      snackErrors += ' Please select a Channel Category.';
    }
    if (snackErrors) {
      setSnackMessage(snackErrors);
      setOpenSnackbar(true);
      return false;
    }
    return true;
  };

  const createSource = (newRow) => {
    /**
     * This function is used to create a new source in the database.
     * It will also be invoked when an existing source's source value was updated
     * because we are creating a new source to ensure we create the correct id of {client}-{source} to prevent duplicates.
     *
     * @param {object} newRow - the row data to be created
     */

    // ensure correct id is created for new source
    newRow.id = `${client}-${newRow.source}`;
    if (newRow.codes || newRow.updatedAt) {
      delete newRow.codes;
      delete newRow.updatedAt;
    }
    APIWrapper.queryApi(
      { mutation: 'createSources' },
      setShouldLogOut,
      { input: newRow },
    )
      .then((resp) => {
        const results = resp.data[0][0] || null;
        if (results) {
          setSnackMessage(`${results.source} was created.`);
          setOpenSnackbar(true);
          if (isDialogInCreateState) {
            const copyRowsUpdated = [results].concat(sourcesPageState.data);
            setSourcesPageState((old) => (
              { ...old, data: copyRowsUpdated, total: old.total + 1 }));
            setisDialogInCreateState(false);
            setIsDialogOpen(false);
          } else {
            // an existing source's source value was updated
            // update existing row value for page state
            const rowIdx = getRowIdxForId(editRowData.id, sourcesPageState.data);
            if (rowIdx > -1) {
              const copyRows = sourcesPageState.data.slice(0);
              copyRows.splice(rowIdx, 1, editRowData);
              setSourcesPageState((old) => ({ ...old, data: copyRows }));
            }
          }
          if (resp.errors) {
            const errMsg = resp.errors[0].message || 'The source could not be created.';
            setSnackMessage(errMsg);
            setOpenSnackbar(true);
            setisDialogInCreateState(false);
            setIsDialogOpen(false);
          }
        }
      })
      .catch((err) => {
        logger.error(err);
        const errMsg = err.errors[0].message || 'The source could not be created.';
        setSnackMessage(errMsg);
        setOpenSnackbar(true);
        setisDialogInCreateState(false);
        setIsDialogOpen(false);
      });
  };

  const updateSource = (copyNewRow) => {
    /**
     * This function is used to update a source in the database.
     *
     * @param {object} copyNewRow - the row data to be updated
     */
    APIWrapper.queryApi(
      { mutation: 'updateSources' },
      setShouldLogOut,
      { input: copyNewRow },
    )
      .then((resp) => {
        const results = resp.data[0][0] || null;
        if (results) {
          // update the row in the table manually
          const rowIdx = getRowIdxForId(editRowData.id, sourcesPageState.data);
          if (rowIdx > -1) {
            const copyRows = sourcesPageState.data.slice(0);
            copyRows.splice(rowIdx, 1, editRowData);
            setSourcesPageState((old) => ({ ...old, data: copyRows }));
          }
          setSnackMessage(`${results.source} was updated.`);
          setOpenSnackbar(true);
          setIsDialogOpen(false);
        } if (resp.errors) {
          const errMsg = `Changes to the row could not be saved. Error: ${resp.errors[0].message}`;
          setSnackMessage(errMsg);
          setOpenSnackbar(true);
          setIsDialogOpen(false);
        }
      })
      .catch((err) => {
        logger.error(err);
        const errMsg = `Changes to the row could not be saved. Error: ${err.errors[0].message}`;
        setSnackMessage(errMsg);
        setOpenSnackbar(true);
        setIsDialogOpen(false);
      });
  };

  const querySourceBySource = (newSource, fn, prevSource) => {
    /**
     * This function is used to check if a source already exists in the database for this client.
     * If it does not exist, it will execute the function passed in as the fn param
     *
     * @param {object} newSource - the row data to be checked
     * @param {function} fn - the function to be called if the source does not already exist
     * @param {string} prevSource - the previous source name if it's being updated
     */
    const { source } = newSource;
    const filter = { client_id: { eq: client } };

    APIWrapper.queryApi(
      { query: 'sourceBySource' },
      setShouldLogOut,
      { source, filter },
    )
      .then((resp) => {
        if (resp.data && resp.data[0] && resp.data[0].length > 0) {
          const errMsg = 'This source already exists so it cannot be created. If you need to update it, search for it and click the edit icon.';
          setSnackMessage(errMsg);
          setOpenSnackbar(true);
          setIsDialogOpen(false);
        } else if (fn === updateSource) {
          // If a source's source value is updating, we do not want to invoke the updateSource function
          // instead we should invoke createSource to prevent issues with the id value
          setPreviousUpdatedId(prevSource.id);
          const sourceUpdateData = {
            oldValue: prevSource.source,
            newValue: source,
          };
          createSource(newSource);
          setUpdatedSource(sourceUpdateData);
          setIsDialogOpen(false);
        } else {
          // make the mutation request
          fn(newSource);
        }
      })
      .catch((err) => {
        logger.error(err);
        const errMsg = `Changes to the row could not be saved. Error: ${err.errors[0].message}`;
        setSnackMessage(errMsg);
        setOpenSnackbar(true);
        setIsDialogOpen(false);
      });
  };

  const saveFunc = (newRow) => {
    /**
     * This function is used to save a source to the database. Handles both create and edit cases.
     *
     * @param {object} newRow - the row data to be saved
     */
    // prep for edit case
    const copyNewRow = { ...newRow };
    // remove createdAt because mutation does not expect it to be passed in.
    if (copyNewRow.createdAt) {
      delete copyNewRow.createdAt;
    }

    // const newRowIsValid = validateSourceRow(newRow);
    if (isDialogInCreateState && authenticated) {
      // create case
      querySourceBySource(newRow, createSource);
    } else if (authenticated) {
      // edit case
      // check for duplicate source only if changing the source value
      const origSource = sourcesPageState.data.filter(
        (r) => r.id === newRow.id,
      );
      if (copyNewRow.source !== origSource[0].source) {
        // handle when source changes, so we need to make sure the id matches source to prevent dupes
        setExistingSourceUpdateId(true);
        querySourceBySource(copyNewRow, updateSource, origSource[0]);
      } else {
        updateSource(copyNewRow);
      }
    }
  };

  const handleClose = (event, reason) => {
    /**
     * This function is used to handle the close event from the snackbar.
     */
    if (reason === 'clickaway') {
      return;
    }

    setSnackWithConfirm(false);
    setOpenSnackbar(false);
    setSnackMessage('');
  };

  const handleSnackDeleteConfirm = () => {
    /**
     * This function is used to handle the delete confirm event from the snackbar.
     */
    deleteFunc(deleteSourceData.id);
    setSnackWithConfirm(false);
    setOpenSnackbar(false);
    setSnackMessage('');
  };

  const memoizeNewRow = useCallback(() => {
    addNewRow();
    setNewSourceRowCounter(newSourceRowCounter + 1);
  });

  const actionForDeleteSource = (
    <>
      <Button color="secondary" size="small" onClick={handleSnackDeleteConfirm}>
        Delete
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  const actionDefault = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  const handleCloseDialog = () => {
    /**
     * This function is used to handle the close event from the create/edit modal.
     */
    setisDialogInCreateState(false);
    setIsDialogOpen(false);
  };

  const handleModalSave = () => {
    /**
     * This function is used to handle the save event from the create/edit modal.
     */
    const isRowValid = validateSourceRow(editRowData);
    if (isDialogInCreateState && isRowValid) {
      setEditRowData(editRowData);
      saveFunc(editRowData);
    } else if (isRowValid) {
      saveFunc(editRowData);
    }
  };

  // responds to onChange event in order to track updates to the row from the
  // create/edit modal/dialog
  // updates state in this component which will be used to send to the graphql mutation
  const setter = (key, value) => {
    // eslint-disable-next-line no-shadow
    setEditRowData((editRowData) => ({ ...editRowData, [key]: value }));
  };

  useEffect(() => {
    const sourcesColumnVisibility = getLocalStorageTablePref('sources', 'column-visibility');
    if (!sourcesColumnVisibility) {
      updateTablePreferenceLocalStorage('sources', 'column-visibility', initColumnVisibilityModel);
    } else {
      setColumnVisibilityModel(sourcesColumnVisibility);
    }
  }, []);

  useEffect(() => {
    const sourcesColumnsLocalStor = getLocalStorageTablePref('sources', 'column');
    const isSourcesColumnsDefUpdated = !diffListOfObjects(
      sourceColumns,
      sourcesColumnsLocalStor,
    );
    if (!sourcesColumnsLocalStor || isSourcesColumnsDefUpdated) {
      updateTablePreferenceLocalStorage('sources', 'column', orderedColumns);
    } else {
      let elToUpdate = sourcesColumnsLocalStor[0];
      elToUpdate = {
        ...elToUpdate,
        getActions: (row) => [
          <GridActionsCellItem
            icon={<EditIcon color={row.row.from_goop ? 'disabled' : ''} />}
            label="Edit"
            className="textPrimary"
            onClick={(ev) => handleEditClick(ev, row)}
            color="inherit"
            size="medium"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon color={row.row.from_goop ? 'disabled' : ''} />}
            label="Delete"
            onClick={(ev) => handleDeleteClick(ev, row)}
            color="inherit"
            size="medium"
          />,
        ],
      };
      sourcesColumnsLocalStor[0] = elToUpdate;
      setOrderedColumns(sourcesColumnsLocalStor);
    }
  }, [sourcesPageState]);

  // this handles sync between Sources and Codes tables, to ensure when an existing source value
  // is updated (Sources table) that the corresponding source value (Codes table) also gets updated
  useEffect(() => {
    if (Object.keys(updatedSource).length > 0) {
      getCodesBySource(updatedSource.oldValue) // this gets codes from the database
        .then((codes) => {
          if (codes.length > 0) {
            const codeIds = [];
            codes.forEach((code) => {
              codeIds.push(code.id);
            });
            // this updates the codes in the database
            updateCodesForSourceChangeFunc(codeIds, updatedSource.newValue, updatedSource.oldValue);
          }
          if (existingSourceUpdateId) {
            // if source's source value was updated, we created the new source with the correct id and
            // associated the codes with the new source, now we will delete the old source
            deleteFunc(previousUpdatedId);
          }
        })
        .catch((err) => {
          logger.error(err);
        });
    }
  }, [updatedSource]);

  const dialogTitlePrefix = isDialogInCreateState ? 'Creating ' : 'Editing ';

  const getDefaultColumns = () => sourceColumns;

  return (
    <>
      <h2>Source Management</h2>
      <div className="header_table_button_container table-actions">
        <Button data-testid="add-source-btn" variant="contained" onClick={memoizeNewRow}>
          Add source
        </Button>
        {client && (
        <AdvancedSearchV2
          currUserSearch={currUserSearch}
          setCurrUserSearch={setCurrUserSearch}
          searchSetter={setAdvancedSearchQuery}
          sortSetter={setAdvancedSearchSort}
          lockedCriteria={lockedCriteria}
          defaultCriteria={defaultSearch}
          defaultSort={defaultSort}
          columns={sourceColumns}
          lockedColumns={['client_id']}
          setUserChips={setUserCriteria}
        />
        )}
      </div>
      {client && (
        <>
          <AdvancedSearchChip
            columns={sourceColumns}
            lockedCriteria={lockedCriteria}
            defaultCriteria={defaultSearch}
            userCriteria={userCriteria}
            setUserCriteria={setUserCriteria}
            onDelete={(index) => { deleteChip(index); }}
          />
          <ServerTable
            pageState={sourcesPageState}
            updatePageState={setSourcesPageState}
            columns={orderedColumns}
            columnVisibilityModel={columnVisibilityModel}
            setColumnVisibilityModel={setColumnVisibilityModel}
            filters={advancedSearchQuery}
            sortModel={advancedSearchSort}
            queryName="searchSources"
            tablePrefix="sources-adv"
            pinnedColumns={{ pinnedColumns: { left: ['actions'] } }}
            createRows={createSourceRows}
            getDefaultColumns={getDefaultColumns}
            setOrderedColumns={() => {}} // ls/saving prefs is in flux, adding this to prevent errs
          />
        </>

      )}
      <Dialog onClose={handleCloseDialog} open={isDialogOpen} maxWidth="sm" fullWidth>
        <DialogTitle>
          {dialogTitlePrefix}
          Sources
        </DialogTitle>
        <Box
          sx={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            flexGrow: 1,
            p: 3,
            m: 1,
            borderRadius: 1,
            gap: '7px',
          }}
        >
          <ClientTextField
            setFunction={setter}
            data={editRowData.client_id}
            attribute="client_id"
            label="Client"
            isDisabled
          />
          <ClientTextField
            setFunction={setter}
            data={editRowData.source}
            attribute="source"
            label="Source"
            testingId="sourceInput"
          />
          <ClientDropDown
            setFunction={setter}
            data={editRowData.channel_category}
            attribute="channel_category"
            label="Channel Category"
            structure={structure}
          />
          {editRowData.channel_category === 'SMS'
            ? (
              <>
                <InputLabel
                  variant="standard"
                  htmlFor="send_date"
                >
                  Send Date
                </InputLabel>
                <CustomDatePicker
                  setCriteria={setter}
                  criteria={editRowData.send_date ? editRowData.send_date : new Date()}
                  name="send_date"
                />
                <ClientDropDown
                  setFunction={setter}
                  data={editRowData.channel}
                  attribute="channel"
                  label="Channel"
                  structure={smsChannelStructure}
                />
                <ClientTextField
                  setFunction={setter}
                  data={editRowData.send_cost}
                  attribute="send_cost"
                  label="Send Cost"
                  type="number"
                />
                <ClientTextField
                  setFunction={setter}
                  data={editRowData.send_size}
                  attribute="send_size"
                  label="Send Size"
                />
              </>
            )
            : (
              <ClientTextField
                setFunction={setter}
                data={editRowData.channel}
                attribute="channel"
                label="Channel"
              />
            )}
          <Button
            data-testid="save-source-btn"
            className="datagrid-rightside-buttons"
            variant="contained"
            style={{
              justifyContent: 'center',
              marginTop: '20px',
              marginBottom: '15px',
            }}
            onClick={handleModalSave}
          >
            Save
          </Button>
        </Box>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={15000}
        onClose={handleClose}
        message={snackMessage}
        action={snackWithConfirm ? actionForDeleteSource : actionDefault}
      />
    </>
  );
}
