import React from 'react';
import {
  Box,
} from '@mui/material';
import TdcProductsCard from '../TdcProductsCard';
import adaiLogo from '../../assets/tdc_product_logos/adai-mark.png';
import missionsmsLogo from '../../assets/tdc_product_logos/mission-sms-mark.png';

function NewToList() {
  const newToListArray = [{
    logo: adaiLogo,
    title: 'Email Acquisition',
    chipText: ['Email'],
    chipColor: ['#f8b94f'],
    upperText: `The Digital Co-Op's AdvantageAI lead generation uses cutting-edge predictive analytics to help identify new likely donors from a massive pool of shared data between co-op partners.  Our co-op delivers high return, scale, and speed to scale while preserving list health and mitigating risk and the usual required expertise.
        `,
    lowerText: 'Organizations and campaigns seeking to grow their file and expand their donor base with precision-targeted leads.',
    showButton: true,
    buttonText: 'Submit a delivery',
    route: '/acquisition',
    deliveryType: 'Acquisition',
    deliveryWindow: 'The delivery timeline for delivery products is 2 business days. The delivery kickoff window is between 9:30 AM-12:30 PM ET daily.',
    pricing: 'CPA per client varies. Total cost per buy must meet a $7,500 minimum unless approved by Ambika and Alex Stein.',
    helpDocumentation: [{ text: 'How to Order a Delivery', link: 'https://missionwired.atlassian.net/wiki/spaces/AAI/pages/3306356988/How+to+Order+AdvantageAI+Email+Acquisition+Delivery' }, { text: 'Strategy Levers', link: 'https://missionwired.atlassian.net/wiki/spaces/AAI/pages/3389161473/AdvantageAI+Email+Acquisition' }],
    collapseOption: true,
  },
  {
    logo: missionsmsLogo,
    title: 'MissionSMS',
    chipText: ['SMS'],
    chipColor: ['#f8b94f'],
    upperText: `This rental service models and identifies supporters completely new to an organization’s universe who are most likely to engage and donate via SMS. Because these supporters are new-to-file, they must be texted via P2P in order to be opted into a Broadcast program. Clients can keep data from audience members who convert (e.g., by donating or sharing their contact information).
        With the Managed Service, MissionWired will model to identify the best possible audience and then deploy text messages on the Co-Op member’s behalf using Scale to Win. With Data Only, the client will receive a list from MissionWired – and then send text messages themselves either once or twice (depending on pricing choice) to that list of numbers. Leads should be pushing for Managed Services buys with their clients.`,
    lowerText: 'Nonprofit and political programs eager to grow their Broadcast file and drive additional revenue in key moments (campaign launches, Giving Tuesday, rapid response, etc.).',
    buttonText: 'Submit a delivery',
    deliveryWindow: 'The delivery timeline for delivery products is 2 business days. The delivery kickoff window is between 9:30 AM-12:30 PM ET daily.',
    pricing: 'CPA per client varies. Total cost per buy must meet a $7,500 minimum unless approved by Ambika and Alex Stein.',
    helpDocumentation: [{ text: 'MissionSMS FAQ', link: 'https://missionwired.atlassian.net/wiki/spaces/AAI/pages/3339911169/MissionSMS' }],
    collapseOption: true,
  },
  {
    logo: '',
    title: 'Lookalike Model',
    chipText: ['Email'],
    chipColor: ['#f8b94f'],
    upperText: `With the lookalike model, we can use The Digital Co-op to solve nearly any challenge an organization is facing. Our two most common use-cases for the lookalike model are advocacy and sustainer growth, but if you have a unique use-case for your client, we’d like to hear about it!
        This cutting-edge tool leverages advanced machine learning to identify and grow your client’s list of potential targeted supporters (advocates, sustainers, mid-level, etc). By comparing behaviors with existing high-value contacts from your client’s list, our tool finds new, engaged names within the co-op that mirror those of your most dedicated advocates.`,
    lowerText: 'Simply attach a CSV file of emails of supporters that align most closely to the names you’d like the model to look for to your Jira ticket, and let our technology do the rest. With a recommended input of about 1-5% of your client’s total names, our model will be primed to pinpoint the most promising new contacts.',
    buttonText: 'View',
    deliveryWindow: 'The delivery timeline for delivery products is 2 business days. The delivery kickoff window is between 9:30 AM-12:30 PM ET daily.',
    pricing: 'CPA per client varies. Total cost per buy must meet a $7,500 minimum unless approved by Ambika and Alex Stein.',
    helpDocumentation: [{ text: 'How to Order a Delivery', link: 'https://missionwired.atlassian.net/wiki/spaces/AAI/pages/3971055683/How+to+Order+AdvantageAI+Email+Lookalike+Delivery' }, { text: 'Strategy Levers', link: 'https://missionwired.atlassian.net/wiki/spaces/AAI/pages/3971055617/AdvantageAI+Email+Lookalike' }],
    collapseOption: true,
  },
  ];

  return (
    <Box>
      {newToListArray.map((item) => (
        <TdcProductsCard
          logo={item.logo}
          title={item.title}
          upperText={item.upperText}
          lowerText={item.lowerText}
          showButton={item.showButton}
          buttonText={item.buttonText}
          route={item.route}
          deliveryType={item.deliveryType}
          deliveryWindow={item.deliveryWindow}
          chipText={item.chipText}
          chipColor={item.chipColor}
          helpDocumentation={item.helpDocumentation}
          collapseOption={item.collapseOption}
        />
      ))}
    </Box>
  );
}

export default NewToList;
