const acquisitionDeliveriesListStructure = {
  // enum values for deliveries acquisition fields
  statesList: [
    'AL',
    'AK',
    'AS',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
  ],
  domainGroupsList: [
    'altice',
    'att',
    'apple',
    'armstrong',
    'atlantic_broadband',
    'century_link',
    'comcast',
    'consolidated_communications',
    'cox',
    'earthlink',
    'echostar',
    'excite',
    'fastmail',
    'fusemail',
    'gci',
    'google',
    'gov',
    'mail',
    'mediacom',
    'microsoft',
    'penteledata',
    'proton',
    'quantum_fiber',
    'rcn',
    'sonic',
    'sparklight',
    'spectrum',
    'tds',
    'united_online',
    'usa.net',
    'vk',
    'wide_open_west',
    'windstream',
    'yahoo',
  ],
  deliveryType: [
    'acquisition',
    'lookalike',
    'reactivation',
    'midlevel_acquisition_interim',
    'sms_rental',
    'sms_list_score',
    'managed_sms',
    'sms_list_append',
  ],
  daysAgoActive: [
    30,
    365,
  ],
  donorFloor: [
    'All',
    'Nonprofit',
    'Political',
  ],
  nonRecentBool: [
    'True',
    'False',
  ],
};

const deliveriesIntFields = [
  'days_ago_active',
  'days_ago_recent',
  'exclude_delivery_type',
  'exclude_delivery_days',
  'number_of_names',
  'number_of_control_names',
];

export { acquisitionDeliveriesListStructure, deliveriesIntFields };
