import React from 'react';
import DromoUploader from 'dromo-uploader-react';
import PropTypes from 'prop-types';

import { dromoFrontendLic } from '../aws-config';

function DromoUpload(props) {
  const {
    importFields,
    importIdentifier,
    allowCustomFields,
    userObject, // user object MUST include an id.
    bulkRowHooks,
    buttonText,
    handleUpload,
  } = props;
  return (
    <div>
      <DromoUploader
        className="dromo-uploader"
        licenseKey={dromoFrontendLic}
        fields={importFields}
        settings={{
          styleOverrides: {
            global: {
              textColor: '#292d2d',
              backgroundColor: '#faf3eb',
            },
            primaryButton: {
              borderRadius: '4px',
              backgroundColor: '#00a69c',
              textColor: '#ffffff',
              border: '1px solid #00a69c',
              hoverBackgroundColor: '#03746d',
              hoverTextColor: '#ffffff',
              hoverBorder: '1px solid #00746d',
            },
            stepperBar: {
              currentColor: '#00a69c',
              completeColor: '#5e6262',
              incompleteColor: '#292d2d',
            },
          },
          importIdentifier,
          backendSyncMode: 'FULL_DATA',
          allowCustomFields,
        }}
        user={userObject}
        onResults={(response, metadata) => {
          handleUpload(metadata);
        }}
        style={{
          height: '40px',
          width: '250px',
          borderRadius: '4px',
          color: '#ffffff',
          padding: '6px 16px',
          fontSize: '14px',
        }}
        bulkRowHooks={bulkRowHooks}
        schemaId="dromo-schema-id"
        schemaName="dromo-schema"
      >
        {buttonText}
      </DromoUploader>
    </div>
  );
}

export default DromoUpload;

DromoUpload.propTypes = {
  importIdentifier: PropTypes.string.isRequired,
  importFields: PropTypes.oneOfType([PropTypes.array]).isRequired,
  allowCustomFields: PropTypes.bool.isRequired,
  userObject: PropTypes.oneOfType([PropTypes.object]).isRequired,
  bulkRowHooks: PropTypes.oneOfType([PropTypes.array]),
  buttonText: PropTypes.string.isRequired,
  handleUpload: PropTypes.func,
};
