import React from 'react';
import PropTypes from 'prop-types';
import {
  TextField, MenuItem, Autocomplete, InputLabel,
} from '@mui/material';
import { capitalizeFirstLetter } from '../../utils';

function DeliveriesDropDown(props) {
  const {
    name,
    value,
    label,
    handleChange,
    menuList,
    textLabel,
    helperText,
    isAutocomplete,
    autocompleteLowercase,
    placeholder,
    isValid,
    customWidth,
  } = props;

  const parseOptionVal = (val) => {
    /**
     * returns value passed into getOptionLabel for autocomplete
     * if autocompleteLowercase is true, formats values to be human readable
     * example: united_online to United online
     *
     * @param {string} val - the input value
     * @returns {int} value - the new value
    */
    let valToShow;
    if (autocompleteLowercase) {
      if (val && typeof val === 'string') {
        valToShow = (capitalizeFirstLetter(val).replaceAll('_', ' ').replaceAll('sms', 'SMS'));
      }
    } else {
      valToShow = val;
    }
    return valToShow;
  };

  return (
    <>
      <InputLabel className="deliveryFormLabel" htmlFor={name}>{label}</InputLabel>
      {isAutocomplete ? (
        <Autocomplete
          id={name}
          name={name}
          multiple
          options={menuList}
          value={value && value.map((val) => val)}
          getOptionLabel={(option) => (parseOptionVal(option))}
          renderInput={(params) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <TextField value={value} variant="outlined" {...params} placeholder={placeholder} />
          )}
          onChange={(event, val) => handleChange({ autocomplete: { [name]: val } })}
          sx={{ margin: name !== 'exclude_delivery_type' ? 'auto' : 0, width: customWidth || '500px' }}
        />
      ) : (
        <TextField
          id={name}
          name={name}
          onChange={handleChange}
          value={value && typeof value === 'string' ? capitalizeFirstLetter(value) : value}
          sx={{ margin: 'auto', width: customWidth || '500px' }}
          select
          error={isValid}
          label={textLabel}
          helperText={helperText}
          variant="outlined"
        >
          {menuList.map((item) => (
            <MenuItem value={item} key={item}>
              {item}
            </MenuItem>
          ))}
        </TextField>
      )}
    </>
  );
}

export default DeliveriesDropDown;

DeliveriesDropDown.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  label: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  menuList: PropTypes.oneOfType([PropTypes.array]),
  textLabel: PropTypes.string,
  helperText: PropTypes.string,
  isAutocomplete: PropTypes.bool,
  autocompleteLowercase: PropTypes.bool,
  placeholder: PropTypes.string,
  isValid: PropTypes.bool,
  customWidth: PropTypes.string,
};
