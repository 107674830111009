import React from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
import { Card, Box } from '@mui/material';
import DeliveriesDropDown from '../deliveriesFields/DeliveriesDropdown';
import DeliveriesTextField from '../deliveriesFields/DeliveriesTextField';
import { logReactErrBoundaryError } from '../../utils';
import { acquisitionDeliveriesListStructure } from '../../utils/deliveriesConstants';
import FallbackOnError from '../FallbackOnError';
import logger from '../../utils/logger';

function AcquisitionPMFields({ formData, handleChange }) {
  return (
    <ErrorBoundary
      FallbackComponent={FallbackOnError}
      onError={logReactErrBoundaryError}
      onReset={(details) => {
        logger.info('Error boundary resetting: ', details);
        // Todo: Reset the state of your app so the error doesn't happen again
      }}
    >
      <Card className="acquisition-form-card">
        <Box sx={{ padding: 2 }}>
          <div style={{
            display: 'grid', gridTemplateColumns: '1fr 1fr', padding: '10px', width: '700px', margin: 'auto',
          }}
          >
            <div>
              <DeliveriesDropDown
                name="days_ago_active"
                value={formData.days_ago_active}
                label="Days Ago Active"
                menuList={acquisitionDeliveriesListStructure.daysAgoActive}
                handleChange={handleChange}
                customWidth="95%"
              />
            </div>
            <div>
              <DeliveriesTextField
                handleChange={handleChange}
                value={formData.days_ago_recent}
                name="days_ago_recent"
                label="Days Ago Recent"
                type="integer"
                customWidth="95%"
              />
            </div>
          </div>
          <div style={{
            display: 'grid', gridTemplateColumns: '1fr 1fr', padding: '10px', verticalAlign: 'center', width: '700px', margin: 'auto',
          }}
          >
            <div>
              <DeliveriesDropDown
                name="exclude_delivery_type"
                placeholder="Select Exclude Delivery Type"
                value={formData.exclude_delivery_type || []}
                label="Exclude Delivery Type"
                isAutocomplete
                autocompleteLowercase
                handleChange={handleChange}
                menuList={acquisitionDeliveriesListStructure.deliveryType}
                customWidth="95%"
              />
            </div>
            <div>
              <DeliveriesTextField
                isDisabled={
                  !formData.exclude_delivery_type
                  || formData.exclude_delivery_type.length === 0
                }
                handleChange={handleChange}
                value={formData.exclude_delivery_days}
                name="exclude_delivery_days"
                label="Exclude Delivery Days"
                customHelperText={!formData.exclude_delivery_type ? 'Exclude delivery type is required before editing this field' : ''}
                customWidth="95%"
              />
            </div>
          </div>
          <div style={{
            display: 'grid', gridTemplateColumns: '1fr 1fr', padding: '10px', verticalAlign: 'center', width: '700px', margin: 'auto',
          }}
          >
            <div>
              <DeliveriesTextField
                handleChange={handleChange}
                value={formData.number_of_control_names || ''}
                name="number_of_control_names"
                label="Number of Control Names"
                type="integer"
                customWidth="95%"
              />
            </div>
            <div>
              <DeliveriesDropDown
                name="allow_nonrecent_data"
                value={formData.allow_nonrecent_data}
                label="Allow Nonrecent Data"
                menuList={acquisitionDeliveriesListStructure.nonRecentBool}
                handleChange={handleChange}
                customWidth="95%"
              />
            </div>
          </div>
          <div style={{
            display: 'grid', gridTemplateColumns: '1fr 1fr', padding: '10px', verticalAlign: 'center', width: '700px', margin: 'auto',
          }}
          >
            <div>
              <DeliveriesDropDown
                name="donor_floor_type"
                value={formData.donor_floor_type}
                label="Donor Floor Type"
                menuList={acquisitionDeliveriesListStructure.donorFloor}
                handleChange={handleChange}
                customWidth="95%"
              />
            </div>
            <div>
              <DeliveriesTextField
                handleChange={handleChange}
                value={formData.desired_donor_floor}
                name="desired_donor_floor"
                label="Desired Donor Floor"
                type="integer"
                customWidth="95%"
              />
            </div>
          </div>
          <Box sx={{ width: '700px', padding: '10px', margin: 'auto' }}>
            <DeliveriesTextField
              handleChange={handleChange}
              value={formData.pm_comments}
              name="pm_comments"
              label="Comments"
              isMultiline
              customWidth="100%"
            />
          </Box>
        </Box>
      </Card>
    </ErrorBoundary>
  );
}

export default AcquisitionPMFields;

AcquisitionPMFields.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object]).isRequired,
  handleChange: PropTypes.func.isRequired,
};
