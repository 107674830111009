import React from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Card, Divider, Chip, Box,
} from '@mui/material';
import DeliveriesTextField from '../deliveriesFields/DeliveriesTextField';
import { logReactErrBoundaryError, capitalizeFirstLetter } from '../../utils';
import FallbackOnError from '../FallbackOnError';
import logger from '../../utils/logger';

function AcquisitionReviewForm({ formData, hasTDCAdminPermission }) {
  return (
    <ErrorBoundary
      FallbackComponent={FallbackOnError}
      onError={logReactErrBoundaryError}
      onReset={(details) => {
        logger.info('Error boundary resetting: ', details);
        // Todo: Reset the state of your app so the error doesn't happen again
      }}
    >
      <Card className="acquisition-form-card">
        <Box sx={{ width: '700px', margin: 'auto' }}>
          <h4>General Information</h4>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', padding: '10px' }}>
            <div>
              <p>Client:</p>
              <DeliveriesTextField
                className="acquisitionReviewField"
                isDisabled
                value={formData.client_id}
                customWidth="95%"
              />
            </div>
            <div>
              <p>Delivery date:</p>
              <DeliveriesTextField
                className="acquisitionReviewField"
                isDisabled
                value={formData.delivery_date}
                customWidth="95%"
              />
            </div>
            <div>
              <p>Number of names:</p>
              <DeliveriesTextField
                className="acquisitionReviewField"
                isDisabled
                value={formData.number_of_names}
                customWidth="95%"
              />
            </div>
            <div>
              <p>Number of Free Names:</p>
              <DeliveriesTextField
                className="acquisitionReviewField"
                isDisabled
                value={formData.number_of_free_names}
                customWidth="95%"
              />
            </div>
            <div>
              <p>Reason:</p>
              <DeliveriesTextField
                className="acquisitionReviewField"
                isDisabled
                value={formData.reason}
                customWidth="95%"
              />
            </div>
            <div>
              <p>Approved By:</p>
              <DeliveriesTextField
                className="acquisitionReviewField"
                isDisabled
                value={formData.approved_by}
                customWidth="95%"
              />
            </div>
            <div>
              <p>Cost per name:</p>
              <DeliveriesTextField
                className="acquisitionReviewField"
                isDisabled
                value={formData.cost_per_name}
                customWidth="95%"
              />
            </div>
            <div>
              <p>Cost per order:</p>
              <DeliveriesTextField
                className="acquisitionReviewField"
                isDisabled
                value={formData.cost_per_order}
                customWidth="95%"
              />
            </div>
            <div>
              <p>Invoice Number:</p>
              <DeliveriesTextField
                className="acquisitionReviewField"
                isDisabled
                value={formData.invoice_number}
                customWidth="95%"
              />
            </div>
            <div>
              <p>Payment Received:</p>
              <DeliveriesTextField
                className="acquisitionReviewField"
                isDisabled
                value={formData.payment_received}
                customWidth="95%"
              />
            </div>
          </div>
          <Divider />
          {formData.isCohortSplit && (
            <>
              <h4>Cohort Percentage Split</h4>
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', padding: '10px' }}>
                {formData.cohortCount.map((cohort) => (
                  <div>
                    <p>
                      {`Cohort ${cohort}`}
                    </p>
                    <DeliveriesTextField
                      className="acquisitionReviewField"
                      isDisabled
                      value={`${formData[`cohort_${cohort}_split`]}%`}
                      customWidth="95%"
                    />
                  </div>
                ))}
              </div>
              <Divider />
            </>
          )}
          <h4>Geographic Restrictions</h4>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', padding: '10px' }}>
            {formData.cohortCount.map((cohort) => (
              <div>
                {formData.cohortCount.length > 1 && (
                  <p>
                    Cohort
                    {' '}
                    {cohort}
                  </p>
                )}
                <p>
                  {capitalizeFirstLetter(formData[`cohort_${cohort}_geographic_exclusion_type`])}
                  {' '}
                  States
                </p>
                {formData[`cohort_${cohort}_states`] ? (
                  <>
                    {formData[`cohort_${cohort}_states`].map((state) => (
                      <Chip
                        variant="outlined"
                        label={state}
                        key={state}
                        sx={{
                          marginRight: '5px',
                          marginBottom: '5px',
                        }}
                      />
                    ))}
                  </>
                ) : (<DeliveriesTextField className="acquisitionReviewField" isDisabled value="" customWidth="95%" />)}
                <p>
                  {capitalizeFirstLetter(formData[`cohort_${cohort}_zipcodes_exclusion_type`])}
                  {' '}
                  Zipcodes
                </p>
                {formData[`cohort_${cohort}_zipcodes`] && formData[`cohort_${cohort}_zipcodes`].filter((z) => z !== '').length > 0 ? (
                  <>
                    {formData[`cohort_${cohort}_zipcodes`].filter((z) => z !== '').map((zip) => (
                      <Chip
                        variant="outlined"
                        label={zip}
                        key={zip}
                        sx={{
                          marginRight: '5px',
                          marginBottom: '5px',
                        }}
                      />
                    ))}
                  </>
                ) : (<DeliveriesTextField className="acquisitionReviewField" isDisabled value="" customWidth="95%" />)}
              </div>
            ))}
          </div>
          <Divider />
          <h4>Domain Restrictions</h4>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', padding: '10px' }}>
            {formData.cohortCount.map((cohort) => (
              <div>
                {formData.cohortCount.length > 1 && (
                  <p>
                    Cohort
                    {' '}
                    {cohort}
                  </p>
                )}
                <p>
                  {capitalizeFirstLetter(formData[`cohort_${cohort}_domain_exclusion_type`])}
                  {' '}
                  Domains
                </p>
                {formData[`cohort_${cohort}_domains`] ? (
                  <>
                    {formData[`cohort_${cohort}_domains`].map((domain) => (
                      <Chip
                        variant="outlined"
                        label={domain}
                        key={domain}
                        sx={{
                          marginRight: '5px',
                          marginBottom: '5px',
                        }}
                      />
                    ))}
                  </>
                ) : (<DeliveriesTextField className="acquisitionReviewField" isDisabled value="" customWidth="95%" />)}
                <p>
                  {capitalizeFirstLetter(formData[`cohort_${cohort}_custom_domain_exclusion_type`])}
                  {' '}
                  Custom Domains
                </p>
                {formData[`cohort_${cohort}_custom_domains`] && formData[`cohort_${cohort}_custom_domains`].filter((z) => z !== '').length > 0 ? (
                  <>
                    {formData[`cohort_${cohort}_custom_domains`].map((custom) => (
                      <Chip
                        variant="outlined"
                        label={custom}
                        key={custom}
                        sx={{
                          marginRight: '5px',
                          marginBottom: '5px',
                        }}
                      />
                    ))}
                  </>
                ) : (<DeliveriesTextField className="acquisitionReviewField" isDisabled value="" customWidth="95%" />)}
              </div>
            ))}
          </div>
          <Divider />
          <div>
            <h4>Uploaded Files</h4>
            <div>
              <h4>Exclusions:</h4>
              {formData.file_uploads.acquistion_exclusions ? (
                <ul>
                  {Object.entries(formData.file_uploads.acquistion_exclusions).map(([filename]) => (
                    <li key={filename}>
                      {filename}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No acquistion exclusions files uploaded.</p>
              )}
            </div>

            <div>
              <h4>Custom Inventory:</h4>
              {formData.file_uploads.custom_universe ? (
                <ul>
                  {Object.entries(formData.file_uploads.custom_universe).map(([universefile]) => (
                    <li key={universefile}>
                      {universefile}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No custom inventory files uploaded.</p>
              )}
            </div>
          </div>
          {hasTDCAdminPermission && (
            <>
              <Divider />
              <h4>PM Fields</h4>
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', padding: '10px' }}>
                <div>
                  <p>Days Ago Active</p>
                  <DeliveriesTextField
                    className="acquisitionReviewField"
                    isDisabled
                    value={formData.days_ago_active}
                    customWidth="95%"
                  />
                </div>
                <div>
                  <p>Days Ago Recent</p>
                  <DeliveriesTextField
                    className="acquisitionReviewField"
                    isDisabled
                    value={formData.days_ago_recent}
                    customWidth="95%"
                  />
                </div>
                <div>
                  <p>Exclude Delivery Type</p>
                  {formData.exclude_delivery_type && formData.exclude_delivery_type.length > 0 ? (
                    <>
                      {formData.exclude_delivery_type.map((type) => (
                        <Chip
                          variant="outlined"
                          label={type}
                          key={type}
                          sx={{
                            marginRight: '5px',
                            marginBottom: '5px',
                          }}
                        />
                      ))}
                    </>
                  ) : (
                    <DeliveriesTextField className="acquisitionReviewField" isDisabled value="" customWidth="95%" />)}
                </div>
                <div>
                  <p>Exclude Delivery Days</p>
                  <DeliveriesTextField
                    className="acquisitionReviewField"
                    isDisabled
                    value={formData.exclude_delivery_days}
                    customWidth="95%"
                  />
                </div>
                <div>
                  <p>Number of Control Names</p>
                  <DeliveriesTextField
                    className="acquisitionReviewField"
                    isDisabled
                    value={formData.number_of_control_names}
                    customWidth="95%"
                  />
                </div>
                <div>
                  <p>Allow Nonrecent Data</p>
                  <DeliveriesTextField
                    className="acquisitionReviewField"
                    isDisabled
                    value={formData.allow_nonrecent_data}
                    customWidth="95%"
                  />
                </div>
                <div>
                  <p>Donor Floor Type</p>
                  <DeliveriesTextField
                    className="acquisitionReviewField"
                    isDisabled
                    value={formData.donor_floor_type}
                    customWidth="95%"
                  />
                </div>
                <div>
                  <p>Desired Donor Floor</p>
                  <DeliveriesTextField
                    className="acquisitionReviewField"
                    isDisabled
                    value={formData.desired_donor_floor}
                    customWidth="95%"
                  />
                </div>
              </div>
              <div style={{ padding: '10px' }}>
                <p>PM Comments</p>
                <DeliveriesTextField
                  className="acquisitionReviewField"
                  isDisabled
                  value={formData.pm_comments}
                  isMultiline
                  customWidth="100%"
                />
              </div>
            </>
          )}
        </Box>
      </Card>
    </ErrorBoundary>
  );
}

export default AcquisitionReviewForm;

AcquisitionReviewForm.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object]).isRequired,
  hasTDCAdminPermission: PropTypes.bool.isRequired,
};
